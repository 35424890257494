import React from 'react';

import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

const ErrorText: React.FC<UIComponentBase> = ({ children, className }) => (
  <p className={clsx('mt-2 text-sm text-red-900', className)}>{children}</p>
);

export default ErrorText;
