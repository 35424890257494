import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import moment from 'moment';

import type { S3Schema, UIFormValues } from '../../@types/types';

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): UIFormValues; (): any };

export const useBermudaDeclarationRelmEoCyberEffects = (
  _schema: S3Schema,
  formValues: UIFormValues,
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn
): void => {
  useEffect(() => {
    const currentBroker = get(formValues, 'wholesale_broker', '');
    if (currentBroker) {
      const nextFormValues = cloneDeep(getFormState());
      // @ts-ignore
      const brokerEmail = currentBroker === 'Other' ? '' : `${currentBroker?.replace(' ', '.')}@edbroking.com`;
      set(nextFormValues, 'wholesale_broker_email', brokerEmail);
      setFormValues(nextFormValues);
    }
  }, [formValues.wholesale_broker]);
  useEffect(() => {
    const nextFormValues = cloneDeep(getFormState());
    if (formValues.inception_date && formValues.number_of_days_premium_to_be_paid_within) {
      set(
        nextFormValues,
        'settlement_due_date',
        moment(formValues?.inception_date)
          .add(formValues.number_of_days_premium_to_be_paid_within, 'days')
          .toISOString()
      );
    } else {
      set(nextFormValues, 'settlement_due_date', null);
    }
    setFormValues(nextFormValues);
  }, [formValues.number_of_days_premium_to_be_paid_within]);
};
