export const A4 = { h: 297, w: 210 };

export const HEADER_H = 150; //px

export const FOOTER_H = 150; //px

/* amount of px between blocks on page */
export const CONTENT_GAP_Y = 16; //gap-y-4 is 16px

/* (t)op and (b)ottom padding amount of page [px] */
export const TB_CONTENT_PADDING = 32; //py-8 is 32px

/* amount of sum of block's padding and margin [px] */
export const BLOCK_PADDING_MARGIN = 16; //px

/* *
 * number of characters (including whitespace)
 * after which text string should be splitted
 * for block splicing */
export const DIVISION_THRESHOLD = 70;

/* fallback value for line height of text with [font-size: 16px] */
export const MIN_LINE_HEIGHT = 24;

/* *
 * number of free lines required to splice block.
 * If lower the block will be moved to the next page */
export const ALLOW_EMPTY_LINES_AT_THE_END = 5;

/* *
 * number of maximum component callback calls.
 * Used for checking ref counter value in components that runs
 * updates that may trigger rerenders to prevent loops */
export const MAX_RERENDER_COUNT = 3;

/* percentage of how much of height to use in allocating pages  */
export const USE_AMOUNT_OF_PAGE = 0.95;

/* amount of sections to be stored in visibleSections array  */
export const ALLOW_VISIBLE_SECTIONS = 3;
