import type { Node } from 'html2json';
import { flattenDeep, isArray, omit } from 'lodash';

import { createNode } from './create-node';

const traverse = require('traverse');

const noListDecoration = { class: 'no-list-style' };
const getOrder = (id?: string): string => (id ? id.split('-')[1] : '1');

export const splitOnTagWithoutRoot = (node: Node): Node[] => {
  if (!node?.child) return [];

  if (isArray(node.child)) {
    return node.child.map((child, idx) => {
      return createNode(
        node.tag!,
        [child],
        idx !== 0
          ? omit(node.attr, 'id')
          : {
              ...node.attr,
            }
      );
    });
  } else {
    return [
      createNode(node.tag!, [node.child], {
        ...node.attr,
      }),
    ];
  }
};

const listTags = ['ul', 'ol', 'dl'];
const listItemTags = ['li', 'dt', 'dd'];

export const listSplitter = (node: Node): Node[] => {
  /**
   * We know for sure that incoming node looks like this:
   * ol | ul > li > ... > more nested lists?
   *
   * We can extract every row "li" and split it into chunks of N rows.
   */

  /**
   * TODO: handle correct list numbering
   */
  let listAttr: any = {};
  let listType: string = 'ul'; //fallback type

  let idx = 1;

  const listEntries: Node[] = traverse(node?.child).reduce(function (
    this: any,
    acc: Node[],
    node: Node | Node[] | string
  ) {
    if (typeof node === 'object' && !(node instanceof Array) && this.level <= 3) {
      if (listItemTags.includes(node?.tag ?? '')) {
        acc.push({ ...node, attr: { ...node.attr, id: `original-${idx}` } });
        idx++;
      }

      if (listTags.includes(node.tag ?? '')) {
        listType = node.tag ?? 'ul';
        listAttr = node.attr;
      }
    }

    return acc;
  },
  []);

  const recreateList = (node: Node): Node => ({
    node: 'root',
    child: [
      createNode(listType, [node], {
        ...(!node.attr?.id ? noListDecoration : { start: getOrder(node.attr?.id as string) }),
        ...listAttr,
      }),
    ],
  });

  return flattenDeep(listEntries.map(splitOnTagWithoutRoot)).map(recreateList);
};
