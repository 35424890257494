import clsx from 'clsx';

import Loader from './Loader';

export interface LoadingOverlayProps {
  active?: boolean;
  onClick?: any;
}

const LoadingOverlay = ({ active, ...props }: LoadingOverlayProps) => {
  return active ? (
    <div
      onClick={props.onClick}
      className={clsx(
        'absolute',
        'top-0',
        'bottom-0',
        'left-0',
        'right-0',
        'z-50',
        'h-full',
        'w-full',
        'bg-gray-700/50',
        'transition',
        'duration-200',
        'ease-in',
        'flex',
        'justify-center',
        'place-items-center',
        'overflow-clip',
        'select-none'
      )}
    >
      <Loader />
    </div>
  ) : null;
};
export default LoadingOverlay;
