import React, { Fragment } from 'react';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuAlt1Icon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import qs from 'qs';

import Logo from '../components/Logo';
import { getInitials } from '../helpers/getInitials';
import { isUserRoute } from '../helpers/isUserRoute';
import { useCurrentUser } from '../hooks/useCurrentUser';

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const { user } = useCurrentUser();
  const { authToken } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const userRoute = isUserRoute(location.pathname);
  return (
    <div className={clsx('h-full overflow-y-hidden max-h-screen', 'relative flex flex-col')}>
      {userRoute && (
        <Disclosure as="nav" className="flex-shrink-0 bg-gray-900">
          {({ open }) => (
            <>
              <div className="w-full px-2 sm:px-4 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                  <div className="flex items-center px-2 lg:px-0">
                    <div className="flex-shrink-0">
                      <Logo />
                    </div>
                  </div>
                  <div className="flex lg:hidden">
                    <Disclosure.Button className="bg-gray-600 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuAlt1Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:block lg:w-80">
                    <div className="flex items-center justify-end">
                      {!authToken && (
                        <Menu as="div" className="ml-4 relative flex-shrink-0">
                          <div>
                            <Menu.Button className="bg-gray-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <span className="h-8 text-sm flex font-semibold items-center text-gray-400 justify-center w-8">
                                {user ? getInitials(user) : ''}
                              </span>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={clsx(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    Settings
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/logout"
                                    className={clsx(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    Logout
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="pt-3 pb-3 border-t border-gray-800">
                  <div className="px-2">
                    <a
                      href="#"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-gray-100 hover:bg-gray-600"
                    >
                      Settings
                    </a>
                    <a
                      href="#"
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-indigo-100 hover:bg-gray-600"
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      {children}
    </div>
  );
};

export default PrivateLayout;
