import usePaginatorStore from '../store';
import type { PageEngineState } from '../store/types';
import { PageBadge } from './PageBadge';
import { PillBadge } from './PillBadge';
import { SectionIndicator } from './SectionIndicator';

interface PageHeaderProps {
  sectionId?: string;
}

export const PageHeader = ({ sectionId }: PageHeaderProps) => {
  const { submission } = usePaginatorStore(({ submission }: PageEngineState) => ({ submission }));

  return (
    <div
      className={`h-header
    w-full flex flex-col p-4 rounded-t border-b border-gray-300 bg-gray-200  z-0
    `}
      id="header"
    >
      <PageBadge icon="lock" text="page header" />
      <div className="py-4 px-10 h-full">
        <div className="flex flex-col">
          <h2>{submission?.class_of_business}</h2>
          <p className="flex items-center gap-3">
            {submission?.name} Last updated&nbsp;
            {submission?.created_at && new Date(submission.created_at).toDateString()}
            <PillBadge>DRAFT</PillBadge>
          </p>
          <SectionIndicator sectionId={sectionId} />
        </div>
      </div>
    </div>
  );
};
