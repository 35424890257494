import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import unset from 'lodash.unset';

import type { S3Schema, UIFormValues } from '../../@types/types';

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): UIFormValues; (): any };

const unsetTexas = (nextFormValues: UIFormValues): void => {
  unset(nextFormValues, 'texas_surplus_tax_rate');
  unset(nextFormValues, 'texas_company_name');
  unset(nextFormValues, 'texas_contact_details');
  unset(nextFormValues, 'texas_agent_name');
  unset(nextFormValues, 'texas_agent_contact_name');
  unset(nextFormValues, 'texas_agent_phone_number');
  unset(nextFormValues, 'texas_insurance_company_contact_name');
  unset(nextFormValues, 'texas_insurance_company_phone_number');
  unset(nextFormValues, 'texas_toll_free_phone_number');
  unset(nextFormValues, 'texas_online_info_or_complaints');
  unset(nextFormValues, 'texas_insurance_company_email');
  unset(nextFormValues, 'texas_insurance_company_address_line_1');
  unset(nextFormValues, 'texas_insurance_company_address_line_2');
  unset(nextFormValues, 'texas_insurance_company_city');
  unset(nextFormValues, 'texas_insurance_company_zip');
  unset(nextFormValues, 'texas_insurance_company_state');
  unset(nextFormValues, 'texas_insurance_company_country');
};

const unsetLouisiana = (nextFormValues: UIFormValues): void => {
  unset(nextFormValues, 'property_in_louisiana');
  unset(nextFormValues, 'louisiana_notice_hurricane_deductible');
  unset(nextFormValues, 'louisiana_notice_hurricane_deductible');
  unset(nextFormValues, 'louisiana_notice_flood_covered');
  unset(nextFormValues, 'louisiana_notice_mold_covered');
  unset(nextFormValues, 'louisiana_notice_coverages_section');
};

export const useBermudaDeclarationEffects = (
  _schema: S3Schema,
  formValues: UIFormValues,
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn
): void => {
  useEffect(() => {
    if (formValues.is_premium_payment_due_in_instalments === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'premium_payment_terms_percentage_or_amount');
      unset(nextFormValues, 'premium_payment_terms_amount_section');
      unset(nextFormValues, 'premium_payment_terms_percentage_section');
      setFormValues(nextFormValues);
    }
  }, [formValues.is_premium_payment_due_in_instalments]);

  useEffect(() => {
    if (formValues.premium_payment_term !== 'PPC TOR 4/86') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'is_premium_payment_due_in_instalments');
      unset(nextFormValues, 'premium_payment_terms_percentage_or_amount');
      unset(nextFormValues, 'premium_payment_terms_amount_section');
      unset(nextFormValues, 'premium_payment_terms_percentage_section');
      setFormValues(nextFormValues);
    }
  }, [formValues.premium_payment_term]);

  useEffect(() => {
    if (
      formValues.two_rock_risk === 'Yes' ||
      formValues.carrier_legal_entity !== 'Liberty Specialty Markets Agency Limited (Bermuda) (8500820v1)'
    ) {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'insurer_1_name');
      unset(nextFormValues, 'insurer_1_percentage');
      unset(nextFormValues, 'insurer_2_name');
      unset(nextFormValues, 'insurer_2_percentage');
      unset(nextFormValues, 'additional_insurers');
      unset(nextFormValues, 'additional_insurer_section');
      setFormValues(nextFormValues);
    }
  }, [formValues.two_rock_risk, formValues.carrier_legal_entity]);

  useEffect(() => {
    if (formValues.does_arbitration_apply === 'Yes' || formValues.us_classification !== 'US Surplus lines') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'service_of_suit_clause');
      unset(nextFormValues, 'service_of_suit_clause_address_line_1');
      unset(nextFormValues, 'service_of_suit_clause_address_line_2');
      unset(nextFormValues, 'service_of_suit_clause_city');
      unset(nextFormValues, 'service_of_suit_clause_zip');
      unset(nextFormValues, 'service_of_suit_clause_state');
      unset(nextFormValues, 'service_of_suit_clause_country');
      setFormValues(nextFormValues);
    }
    if (formValues.does_arbitration_apply === 'No' || formValues.us_classification !== 'US Surplus lines') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'location_of_arbitration_other');
      setFormValues(nextFormValues);
    }
    if (
      formValues.does_arbitration_apply === 'No' ||
      formValues.us_classification !== 'Non Regulated / Exempt – Direct Procurement'
    ) {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'location_of_arbitration');
      unset(nextFormValues, 'location_of_arbitration_other');
      setFormValues(nextFormValues);
    }
  }, [formValues.does_arbitration_apply, formValues.us_classification]);

  useEffect(() => {
    if (formValues.location_of_arbitration !== 'Other') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'location_of_arbitration_other');
      setFormValues(nextFormValues);
    }
  }, [formValues.location_of_arbitration]);

  useEffect(() => {
    const nextFormValues = cloneDeep(getFormState());
    if (
      ![
        'Non Regulated / Exempt – Direct Procurement',
        'Non-Regulated / Exempt - Non-US Risk',
        'Reinsurance',
        'Various',
      ].includes(formValues.us_classification as string)
    ) {
      unset(nextFormValues, 'country_of_jurisdiction');
      unset(nextFormValues, 'state_of_jurisdiction');
      unset(nextFormValues, 'province_of_jurisdiction');
      unset(nextFormValues, 'uk_country');
      unset(nextFormValues, 'country_of_jurisdiction_other');
    }
    if (formValues.us_classification !== 'US Surplus lines') {
      unset(nextFormValues, 'use_oversees_consultant_name');
      unset(nextFormValues, 'state_of_filing');
      unsetTexas(nextFormValues);
      unsetLouisiana(nextFormValues);
    }
    setFormValues(nextFormValues);
  }, [formValues.us_classification]);

  useEffect(() => {
    if (!['Texas', 'Louisiana'].includes(formValues.state_of_filing as string)) {
      const nextFormValues = cloneDeep(getFormState());
      unsetTexas(nextFormValues);
      unsetLouisiana(nextFormValues);
      setFormValues(nextFormValues);
    }
    if (formValues.state_of_filing === 'Texas') {
      const nextFormValues = cloneDeep(getFormState());
      unsetLouisiana(nextFormValues);
      setFormValues(nextFormValues);
    }
    if (formValues.state_of_filing === 'Louisiana') {
      const nextFormValues = cloneDeep(getFormState());
      unsetTexas(nextFormValues);
      setFormValues(nextFormValues);
    }
  }, [formValues.state_of_filing]);
};
