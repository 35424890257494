import qs from 'qs';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import type { EDDocumentType, ServerResponse, Template, UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';
import { fetchTemplate } from '../queries';

interface TemplateDataHook {
  data: ServerResponse<Template> | undefined;
  status: UseQueryStatus;
  isEditorRoute: boolean;
  isMainTemplateRoute: boolean;
  documentType: EDDocumentType | '';
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useTemplateData = (): TemplateDataHook => {
  const location = useLocation();
  const { id = '' } = useParams<{ id: string }>();
  const isEditorRoute = location.pathname.includes(`/templates/${id}/editor`);
  const isMainTemplateRoute = location.pathname === `/templates/${id}`;
  const queryParams: { authToken?: string } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, status, isFetching, refetch } = useQuery(
    ['template', id],
    () => fetchTemplate(id!, queryParams.authToken ?? ''),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    status,
    isEditorRoute,
    isMainTemplateRoute,
    documentType: data?.data?.document_type ?? '',
    isFetching,
    refetch,
  };
};
