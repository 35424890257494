import qs from 'qs';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import type { EDDocumentType, UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';
import type { XSubmissionData } from '../containers/ContractBuilder/types';
import { fetchXSubmission } from '../queries/fetchSubmissionExperimental';

interface SubmissionDataHook {
  data: XSubmissionData | undefined;
  status: UseQueryStatus;
  isEditorRoute: boolean;
  isMainSubmissionRoute: boolean;
  documentType: EDDocumentType | '';
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useSubmissionDataExperimental = (): SubmissionDataHook => {
  const location = useLocation();
  const { id = '' } = useParams<{ id: string }>();
  const isEditorRoute = location.pathname.includes(`/submissions/${id}/editor`);
  const isMainSubmissionRoute =
    location.pathname === `/submissions/${id}` || location.pathname === `/submissions/${id}/`;
  const queryParams: { authToken?: string } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { data, status, isFetching, refetch } = useQuery(
    ['submission', id],
    () => fetchXSubmission(id!, queryParams?.authToken ?? ''),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    status,
    isEditorRoute,
    isMainSubmissionRoute,
    documentType: '',
    isFetching,
    refetch,
  };
};
