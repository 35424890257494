import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import usePaginatorStore from '../store';

const AddBlockButton = () => {
  const { openModal } = usePaginatorStore(({ openModal, sections }) => ({ openModal, sections }));

  const onClick = () => openModal({ section_id: 'risk_details' }, 'create');
  return (
    <div className="mx-4 py-2">
      <Button className="w-full" onClick={onClick}>
        Add new block <Icon name="plus" />
      </Button>
    </div>
  );
};

export default AddBlockButton;
