import React, { useState, useEffect, useRef } from 'react';

import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';

import type { UIOnChangeFn } from '../../@types/types';
import Icon from './Icon';
import type { InputFieldProps } from './InputField';
import InputField from './InputField';
import InputReadOnly from './InputReadOnly';
import { MenuContainer, sizes, styles } from './InputSelect';
import './InputMultiSelect.css';

const SELECT_LABEL_MAX_LENGTH = 20;

const selectedItemStyle = 'bg-gray-200 rounded-md bg-rounded py-1 -my-1 pl-3 pr-2 mr-1 space-x-1 text-sm font-normal';
const formatItemLabel = (item: string, selectLabelMaxLength: number): string =>
  item.length > selectLabelMaxLength ? `${item.slice(0, selectLabelMaxLength)}...` : item;

export interface InputMultiSelectProps extends InputFieldProps {
  isReadOnly?: boolean;
  isSearchable?: boolean;
  options: string[];
  value?: string | string[];
  'aria-labelledby'?: string;
  formatter?: (value: string) => string;
  itemFormatter?: (value: string) => string;
  onChange: UIOnChangeFn<string[]>;
  onSelect: (args: unknown) => void;
  initialSelectedItem?: string[];
  placeholder?: string;
  selectLabelMaxLength?: number;
}

const InputMultiSelect: React.FC<InputMultiSelectProps> = ({
  className,
  descriptionText,
  errors,
  isReadOnly,
  isSearchable = true,
  labelText,
  placeholder = 'Select',
  onChange,
  onSelect: _onSelect,
  options,
  name = 'multiselect',
  size = 'normal',
  value = [],
  selectLabelMaxLength = SELECT_LABEL_MAX_LENGTH,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } =
    useMultipleSelection({
      initialSelectedItems: Array.isArray(value) ? value : typeof value === 'string' ? [value] : [],
    });
  const getFilteredItems = (items: string[]) =>
    items.filter(
      (item) =>
        selectedItems.indexOf(item) < 0 &&
        String(item ?? '')
          .toLowerCase()
          .includes(String(inputValue ?? '').toLowerCase())
    );
  const inputRef = useRef(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox<string | null>({
    // value,
    /* ^ this shouldn't be here as it is not specified in UseComboboxProps<T>
    TODO: check if removing value from here will break anything
    */
    items: getFilteredItems(options),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    onChange(selectedItems, name);
  }, [selectedItems]);

  const isFull = getFilteredItems(options).length === 0;

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      name={name}
      errors={errors}
      {...props}
    >
      {isReadOnly && (!value || !value?.length) && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={''}
        />
      )}

      {isReadOnly && value && value.length && (
        <div {...getMenuProps()}>
          <ul className={styles.menuInner}>
            {Array.isArray(value) &&
              value.map((item, index) => (
                <li className={clsx(styles.menuItem, 'cursor-not-allowed')} key={`${item}${index}`}>
                  {item}
                </li>
              ))}
          </ul>
        </div>
      )}

      {!isReadOnly && (
        <div className={clsx('relative')}>
          <div
            className={clsx(
              'flex justify-between cursor-pointer border rounded-md border-gray-300 py-2.5 px-3 pb-0.5 multi-select',
              sizes[size]
            )}
            {...getToggleButtonProps()}
          >
            <div className="flex multi-select-wrapper" ref={inputRef}>
              {selectedItems.map((selectedItem, index) => (
                <div className={selectedItemStyle} key={`selected-item-${index}`}>
                  <span {...getSelectedItemProps({ selectedItem, index })} title={selectedItem}>
                    {formatItemLabel(selectedItem, selectLabelMaxLength)}
                  </span>
                  <span
                    className="cursor-pointer hover:bg-gray-400 rounded-full px-1 "
                    onClick={(ev) => {
                      ev.stopPropagation();
                      removeSelectedItem(selectedItem);
                    }}
                  >
                    &#10005;
                  </span>
                </div>
              ))}
              {selectedItems.length === 0 && (
                <label className="text-sm font-normal text-gray-700" {...getLabelProps()}>
                  {placeholder}
                </label>
              )}
            </div>
            <div className="flex" {...getComboboxProps()}>
              <input className="hidden" {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))} />
              {!isOpen && <Icon name="chevron-down" className="ml-2 fill-current text-gray-800 self-center" />}

              {isOpen && <Icon name="chevron-up" className="ml-2 fill-current text-blue-700 self-center" />}
            </div>
          </div>
          <div {...getMenuProps()}>
            {isOpen && (
              <MenuContainer anchorRef={inputRef}>
                {isSearchable && (
                  <input autoFocus className={styles.input} placeholder="Search..." {...getInputProps()} />
                )}
                <ul className={styles.menuInner}>
                  {getFilteredItems(options).map((item, index) => (
                    <li
                      className={clsx(styles.menuItem, index === highlightedIndex && 'bg-gray-100')}
                      key={`${item}${index}`}
                      {...getItemProps({ item, index })}
                    >
                      {item}
                    </li>
                  ))}
                  {isFull && <li className={clsx(styles.menuItem, 'italic opacity-60')}>No options left</li>}
                </ul>
              </MenuContainer>
            )}
          </div>
        </div>
      )}
    </InputField>
  );
};

export default InputMultiSelect;
