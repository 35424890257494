import React from 'react';

interface SvgProps {
  name: SvgIconName;
}

const Svg: React.FC<SvgProps> = ({ name }) => {
  switch (name) {
    case 'create':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M20.719 7.031l-1.828 1.828-3.75-3.75 1.828-1.828q0.281-0.281 0.703-0.281t0.703 0.281l2.344 2.344q0.281 0.281 0.281 0.703t-0.281 0.703zM3 17.25l11.063-11.063 3.75 3.75-11.063 11.063h-3.75v-3.75z" />
        </svg>
      );

    case 'double-arrow':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M8.484 5.016l5.016 6.984-5.016 6.984h-4.5l5.016-6.984-5.016-6.984h4.5zM15.516 5.016l4.969 6.984-4.969 6.984h-4.5l4.969-6.984-4.969-6.984h4.5z" />
        </svg>
      );

    case 'insert-drive-file':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12.984 9h5.531l-5.531-5.484v5.484zM6 2.016h8.016l6 6v12q0 0.797-0.609 1.383t-1.406 0.586h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586z" />
        </svg>
      );

    case 'file-copy':
      return (
        <svg id="icon-file_copy" viewBox="0 0 24 24">
          <path d="M14.016 12h5.484l-5.484-5.484v5.484zM15 5.016l6 6v9.984q0 0.797-0.609 1.406t-1.406 0.609h-11.016q-0.797 0-1.383-0.609t-0.586-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h6.984zM15.984 0.984v2.016h-12v14.016h-1.969v-14.016q0-0.797 0.586-1.406t1.383-0.609h12z" />
        </svg>
      );

    case 'replay':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12 5.016q3.328 0 5.672 2.344t2.344 5.625q0 3.328-2.367 5.672t-5.648 2.344-5.648-2.344-2.367-5.672h2.016q0 2.484 1.758 4.242t4.242 1.758 4.242-1.758 1.758-4.242-1.758-4.242-4.242-1.758v4.031l-5.016-5.016 5.016-5.016v4.031z" />
        </svg>
      );

    case 'clock':
      return (
        <svg id="icon-access_time" viewBox="0 0 24 24">
          <path d="M12.516 6.984v5.25l4.5 2.672-0.75 1.266-5.25-3.188v-6h1.5zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z" />
        </svg>
      );

    case 'maximize':
      return (
        <svg id="icon-crop_square" viewBox="0 0 24 24">
          <polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" />
        </svg>
      );

    case 'minimize':
      return (
        <svg id="icon-minimize" viewBox="0 0 24 24">
          <path d="M6 18.984h12v2.016h-12v-2.016z" />
        </svg>
      );

    case 'unfold-less':
      return (
        <svg id="icon-unfold_less" viewBox="0 0 24 24">
          <path d="M16.594 5.391l-4.594 4.594-4.594-4.594 1.406-1.406 3.188 3.188 3.188-3.188zM7.406 18.609l4.594-4.594 4.594 4.594-1.406 1.406-3.188-3.188-3.188 3.188z" />
        </svg>
      );

    case 'unfold-more':
      return (
        <svg id="icon-unfold_more" viewBox="0 0 24 24">
          <path d="M12 18.188l3.188-3.188 1.406 1.406-4.594 4.594-4.594-4.594 1.406-1.406zM12 5.813l-3.188 3.188-1.406-1.406 4.594-4.594 4.594 4.594-1.406 1.406z" />
        </svg>
      );

    case 'messages':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18 8.016v-2.016h-12v2.016h12zM18 11.016v-2.016h-12v2.016h12zM18 14.016v-2.016h-12v2.016h12zM20.016 2.016q0.797 0 1.383 0.586t0.586 1.383v12q0 0.797-0.586 1.406t-1.383 0.609h-14.016l-3.984 3.984v-18q0-0.797 0.586-1.383t1.383-0.586h16.031z" />
        </svg>
      );

    case 'find-in-page':
      return (
        <svg viewBox="0 0 24 24">
          <path d="M9 12.984q0-1.219 0.891-2.109t2.109-0.891 2.109 0.891 0.891 2.109-0.891 2.109-2.109 0.891-2.109-0.891-0.891-2.109zM20.016 19.594l-3.844-3.844q0.844-1.313 0.844-2.766 0-2.063-1.477-3.516t-3.539-1.453-3.539 1.453-1.477 3.516 1.477 3.539 3.539 1.477q1.453 0 2.766-0.844l4.406 4.453q-0.516 0.375-1.172 0.375h-12q-0.797 0-1.406-0.586t-0.609-1.383l0.047-16.031q0-0.797 0.586-1.383t1.383-0.586h8.016l6 6v11.578z" />
        </svg>
      );

    case 'upload':
      return (
        <svg viewBox="0 0 14 14">
          <path d="M0 1.63336L14 1.63336L14 2.88486e-05L1.42791e-07 2.76247e-05L0 1.63336Z" />
          <path d="M8.54175 14L4.79175 14L4.79175 8.44444L1.66675 8.44444L6.66675 4L11.6667 8.44444L8.54175 8.44444L8.54175 14Z" />
        </svg>
      );

    case 'calendar':
      return (
        <svg viewBox="0 0 16 16">
          <path d="M16 3.886V2.9a.5.5 0 00-.5-.5h-2.014V0H10.97v2.514H5.03V0H2.514v2.514H.457A.457.457 0 000 2.971V15.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V3.886zM1.6 5.943h12.686v8.343H1.6V5.943z" />
        </svg>
      );

    case 'checkmark':
      return (
        <svg viewBox="0 0 12 10">
          <path d="M3.916 7.645L1.204 4.8l-.918.963 3.63 3.808 7.798-8.18-.918-.962-6.88 7.216z" />
        </svg>
      );

    case 'warning':
      return (
        <svg viewBox="0 0 48 48">
          <path d="M24 4.8C13.396 4.8 4.8 13.396 4.8 24S13.396 43.2 24 43.2 43.2 34.604 43.2 24 34.604 4.8 24 4.8zM0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z" />
          <path d="M21.6 28.457V11.314h4.8v17.143h-4.8zM21.6 36.686v-4.8h4.8v4.8h-4.8z" />
        </svg>
      );

    case 'paperclip':
      return (
        <svg viewBox="0 0 14 14">
          <path d="M9.045 3.182V10.5a2.545 2.545 0 11-5.09 0V2.545a1.591 1.591 0 013.181 0v6.682c0 .35-.286.637-.636.637a.638.638 0 01-.636-.637V3.182h-.955v6.045a1.591 1.591 0 003.182 0V2.545a2.545 2.545 0 10-5.091 0V10.5C3 12.434 4.565 14 6.5 14s3.5-1.566 3.5-3.5V3.182h-.955z" />
        </svg>
      );

    case 'lock':
      return (
        <svg viewBox="0 0 12 16">
          <path
            fillRule="evenodd"
            d="M4.514 3.086C4.514 2.27 5.184 1.6 6 1.6c.815 0 1.486.67 1.486 1.486v2.628H4.514V3.086zm-1.6 2.628V3.086A3.092 3.092 0 016 0a3.092 3.092 0 013.086 3.086v2.628h2.628V16H.286V5.714h2.628zM6 12.571a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
            clipRule="evenodd"
          />
        </svg>
      );

    case 'lock2':
      return (
        <svg viewBox="0 0 12 14" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 6.2001V4.6001C2 2.39096 3.79086 0.600098 5.99999 0.600098C8.20913 0.600098 9.99999 2.39096 9.99999 4.6001V6.2001C10.8836 6.2001 11.6 6.91644 11.6 7.8001V11.8001C11.6 12.6838 10.8836 13.4001 9.99999 13.4001H2C1.11634 13.4001 0.399998 12.6838 0.399998 11.8001V7.8001C0.399998 6.91644 1.11634 6.2001 2 6.2001ZM8.39999 4.6001V6.2001H3.6V4.6001C3.6 3.27461 4.67451 2.2001 5.99999 2.2001C7.32548 2.2001 8.39999 3.27461 8.39999 4.6001Z"
            fill="#6B7280"
          />
        </svg>
      );

    case 'edit':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      );

    case 'search':
      return (
        <svg viewBox="0 0 16 16">
          <path d="M5.486 1.6a3.886 3.886 0 100 7.771 3.886 3.886 0 000-7.771zM0 5.486a5.486 5.486 0 1110.971 0A5.486 5.486 0 010 5.486z" />
          <path d="M10.83 9.34a.5.5 0 01.244.134l4.572 4.572a.5.5 0 010 .708l-.892.892a.5.5 0 01-.707 0l-4.573-4.572a.5.5 0 01-.134-.245l-.426-1.915 1.915.426z" />
        </svg>
      );

    case 'cross':
      return (
        <svg viewBox="0 0 14 14">
          <path d="M6.012 7L1 12.012l.988.988L7 7.988 12.012 13l.988-.988L7.988 7 13 1.988 12.012 1 7 6.012 1.988 1 1 1.988 6.012 7z" />
        </svg>
      );

    case 'info':
      return (
        <svg viewBox="0 0 13 14">
          <path d="M6.5 12.2a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4zM13 7A6.5 6.5 0 110 7a6.5 6.5 0 0113 0z" />
          <path d="M7.15 5.793v4.643h-1.3V5.793h1.3zM7.15 3.564v1.3h-1.3v-1.3h1.3z" />
        </svg>
      );

    case 'chevron-up':
      return (
        <svg width="12" height="7">
          <path fillRule="evenodd" d="M0 6l1 1 5-5 5 5 1-1-6-6-1 1-5 5z" clipRule="evenodd" />
        </svg>
      );

    case 'chevron-down':
      return (
        <svg width="12" height="7">
          <path fillRule="evenodd" d="M12 1l-1-1-5 5-5-5-1 1 6 6 1-1 5-5z" clipRule="evenodd" />
        </svg>
      );

    case 'circle-edit':
      return (
        <svg viewBox="0 0 32 32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#339AF0" />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M17.951 11.596l2.1-2.1a.5.5 0 01.707 0l1.746 1.746a.5.5 0 010 .707l-2.1 2.1-2.453-2.453zm-1.003 1.003l2.453 2.453-7.659 7.659a.5.5 0 01-.353.146H9.143v-2.246a.5.5 0 01.146-.353l7.659-7.659z"
            clipRule="evenodd"
          />
        </svg>
      );

    case 'circle-check':
      return (
        <svg viewBox="0 0 32 32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#51CF66" />
          <path fill="#fff" d="M13.916 18.645L11.204 15.8l-.918.963 3.63 3.808 7.798-8.179-.918-.963-6.88 7.216z" />
        </svg>
      );

    case 'circle-lock':
      return (
        <svg width="32" height="32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#E9ECEF" />
          <path
            fill="#495057"
            fillRule="evenodd"
            d="M14.514 11.086c0-.816.67-1.486 1.486-1.486.815 0 1.486.67 1.486 1.486v2.628h-2.972v-2.628zm-1.6 2.628v-2.628A3.092 3.092 0 0116 8a3.092 3.092 0 013.086 3.086v2.628h2.628V24H10.286V13.714h2.628zM16 20.571a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
            clipRule="evenodd"
          />
        </svg>
      );

    case 'logout':
      return (
        <svg width="15" height="14">
          <g fill="#495057" fillRule="evenodd">
            <path d="M10.42 0h-9a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h9.9a.5.5 0 00.5-.5v-3h-1.4v2.1h-8.1V1.4h8.1v2.1h1.4v-3a.5.5 0 00-.5-.5h-.9z" />
            <path d="M11.82 4.4l3.1 2.6-3.1 2.6V7.7h-7.8V6.3h7.8V4.4z" />
          </g>
        </svg>
      );

    case 'details':
      return (
        <svg width="26" height="26" viewBox="0 0 26 26">
          <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
        </svg>
      );

    case 'trashcan':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
        </svg>
      );

    case 'star-empty':
      return (
        <svg id="icon-star-empty" viewBox="0 0 32 32">
          <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798zM16 23.547l-6.983 3.671 1.334-7.776-5.65-5.507 7.808-1.134 3.492-7.075 3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776-6.983-3.671z"></path>
        </svg>
      );

    case 'star-full':
      return (
        <svg id="icon-star-full" viewBox="0 0 32 32">
          <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798z"></path>
        </svg>
      );
    case 'dots-vertical':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          />
        </svg>
      );
    case 'hand-empty':
      return (
        <svg
          id="icon-hand-empty"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
          />
        </svg>
      );
    case 'settings':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      );
    case 'plus':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
        </svg>
      );
    case 'arrow-up':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      );
    case 'arrow-down':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
        </svg>
      );
    case 'comment':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
          />
        </svg>
      );
    case 'layout':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
        </svg>
      );
    case 'align-left':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={'w-6 h-6'}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
        </svg>
      );

    default:
      return null;
  }
};

export default Svg;

export type SvgIconName =
  | 'create'
  | 'double-arrow'
  | 'insert-drive-file'
  | 'file-copy'
  | 'replay'
  | 'clock'
  | 'maximize'
  | 'minimize'
  | 'unfold-less'
  | 'unfold-more'
  | 'messages'
  | 'find-in-page'
  | 'upload'
  | 'calendar'
  | 'checkmark'
  | 'warning'
  | 'paperclip'
  | 'lock'
  | 'lock2'
  | 'edit'
  | 'search'
  | 'cross'
  | 'info'
  | 'chevron-up'
  | 'chevron-down'
  | 'circle-edit'
  | 'circle-check'
  | 'circle-lock'
  | 'logout'
  | 'details'
  | 'trashcan'
  | 'star-empty'
  | 'star-full'
  | 'hand-empty'
  | 'plus'
  | 'settings'
  | 'arrow-up'
  | 'arrow-down'
  | 'comment'
  | 'dots-vertical'
  | 'layout'
  | 'align-left';
