import React, { useMemo } from 'react';

import { ArrowLeftIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import type { EDDocumentStatus, EDDocumentType, S3Schema } from '../../../../../@types/types';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Datapoints from '../../../../Datapoints';
import ClauseLibrary from '../../../ClauseLibrary';
import './index.css';

const mrcSchemasIds = ['mrc-clauses-library', 'template-mrc-clauses-library'];
const mrcDocumentTypes = ['MRC', 'TEMPLATE_MRC'];

interface VerticalTabsProps {
  schemas: S3Schema[] | undefined;
  activeSchemaName: string;
  documentType: EDDocumentType | '';
  isDisabled: boolean;
  shouldShowSections?: boolean;
  isNavigationLocked: boolean;
  hasEditorUnsavedChanges: boolean;
  status: EDDocumentStatus | undefined;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  onStartOver: () => void;
  onSelectSchema: (schema: S3Schema) => void;
}

export const VerticalTabs: React.FC<VerticalTabsProps> = ({
  activeSchemaName,
  onSelectSchema,
  schemas,
  setIsValid,
  shouldShowSections = true,
  isDisabled,
  isNavigationLocked = false,
  documentType,
  onStartOver,
}) => {
  const activeSchema = useMemo(
    () => schemas?.find((schema) => schema.db_name === activeSchemaName),
    [activeSchemaName, schemas]
  );

  const handleSelectSchema = (schema: S3Schema) => {
    if (isNavigationLocked) {
      return;
    }

    return onSelectSchema(schema);
  };

  return (
    <div className={clsx('overflow-auto h-full relative')}>
      <div className="flex">
        <div className="w-full h-full relative">
          {documentType === 'MRC' && (
            <div className="text-xs py-4 font-medium px-4 uppercase text-gray-500 tracking-wide border-b border-gray-200">
              <Button onClick={onStartOver} className="h-10" kind="ghost" size="small">
                <ArrowLeftIcon className="block h-4 w-4 mr-2" />
                Start Over
              </Button>
            </div>
          )}
          {shouldShowSections && (
            <>
              <div className="text-xs py-4 font-medium px-4 uppercase text-gray-500 tracking-wide border-b border-gray-200">
                contract section
              </div>
              <ul className="relative pt-4 px-2">
                {schemas?.map((schema) => (
                  <React.Fragment key={`${schema.db_name}-schema-section`}>
                    <li className="">
                      <button
                        type="button"
                        className={clsx('w-full', isNavigationLocked && 'text-gray-200 cursor-default')}
                        onClick={() => handleSelectSchema(schema)}
                      >
                        <span
                          className={clsx(
                            ' w-full mb-1 flex text-left px-3 py-2 text-sm font-medium rounded-md',
                            isNavigationLocked && 'text-gray-300',
                            !isNavigationLocked && 'hover:bg-gray-50 hover:text-gray-900 text-gray-600',
                            activeSchemaName === schema.db_name && 'bg-gray-100 text-gray-900'
                          )}
                        >
                          {schema.name}
                        </span>
                      </button>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </>
          )}
          <div className="text-xs py-4 font-medium px-4 uppercase text-gray-500 tracking-wide border-b border-gray-200">
            Structured data points
          </div>
          <ul className="relative">
            {activeSchema && (
              <React.Fragment key={`${activeSchema.db_name}-form-section`}>
                {Object.keys(activeSchema.properties).length > 0 && (
                  <Card className="">
                    <Card.Content className="pb-8">
                      <Datapoints
                        name={activeSchema.db_name}
                        schema={activeSchema}
                        isDisabled={isDisabled}
                        setIsValid={setIsValid}
                        documentType={documentType}
                      />
                    </Card.Content>
                  </Card>
                )}
              </React.Fragment>
            )}
          </ul>
          {activeSchema && mrcSchemasIds.includes(activeSchema.$id) && mrcDocumentTypes.includes(documentType) && (
            <ClauseLibrary />
          )}
        </div>
      </div>
    </div>
  );
};
