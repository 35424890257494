import produce from 'immer';
import { sortBy } from 'lodash';
import create from 'zustand';

import { patchXSubmission } from '../../../mutations/patchXSubmission';
import { fetchXSubmission } from '../../../queries/fetchSubmissionExperimental';
import { ALLOW_VISIBLE_SECTIONS } from '../constants';
import { handleSectionsRefresh, processServerSubmission, resetFakeOrder } from '../helpers';
import { XSubmissionAPIOperations } from '../types';
import type { BlockFragment } from '../types';
import type { PageEngineState } from './types';
import { findBlockIdxById, findNextFreeOrder, getOriginalBlock, removeBlockFromSection } from './utils';

const usePaginatorStore = create<PageEngineState>((set, get) => ({
  data: [],
  sections: {},
  settings: { open: false },
  modal: { open: false },
  visibleSections: [],
  currentSection: '',
  setCurrentSection: (section_id: string) => set({ currentSection: section_id }),
  setVisibleSection: (section_id) => {
    const alreadyVisible = get().visibleSections.includes(section_id);
    get().setCurrentSection(section_id);
    if (alreadyVisible) return;
    console.log('setVisibleSection', section_id);

    set((state) => {
      const current = state.visibleSections ?? [];
      const lastElements = current.slice(-ALLOW_VISIBLE_SECTIONS);
      return { visibleSections: [...lastElements, section_id] };
    });
    get().refreshSectionById(section_id);
  },
  initSubmission: async (submission) => {
    resetFakeOrder();
    set({ submission });
    get().submissionToSections();
  },
  submissionToSections: () => {
    console.log('zustand:mapDataToSections');
    set(
      produce((state) => {
        state.sections = processServerSubmission(state.submission?.blocks);
      })
    );
  },
  submissionToSectionsById: (section_id: string) => {
    console.log('zustand:mapDataToSectionsById', section_id);

    set(
      produce((state) => {
        state.sections[section_id] = processServerSubmission({ [section_id]: state.submission.blocks[section_id] })[
          section_id
        ];
      })
    );
    get().refreshSectionById(section_id);
  },
  refreshSections: () => {
    console.log('zustand:refreshSections');
    set(
      produce((state) => {
        state.sections = handleSectionsRefresh(state.sections);
      })
    );
  },
  refreshSectionById: (section_id: string) => {
    const visibleSections = get().visibleSections;
    const isVisible = visibleSections.includes(section_id);

    if (!isVisible) return;
    console.log('zustand:refreshSectionsById', section_id);

    set(
      produce((state) => {
        state.sections[section_id] = handleSectionsRefresh({ [section_id]: state.sections[section_id] })[section_id];
      })
    );
  },
  createBlock: async (block) => {
    console.log('zustand:createBlock', block);
    const { section_id, name, content } = block;
    const submission = get().submission;

    if (!submission) {
      console.log('zustand:deleteBlock:submission not found');
      return;
    }

    const result = await patchXSubmission(
      submission.id,
      {
        operation: XSubmissionAPIOperations.CREATE,
        block: { name, content, section_id },
      },
      ''
    );

    if (result?.message === 'success') {
      await fetchXSubmission(submission.id, '').then((submission) => {
        set({ submission });
        get().submissionToSectionsById(section_id);
      });
    }
  },
  updateBlock: async (block) => {
    console.log('zustand:updateBlock', block);
    const { section_id, name, content } = block;
    const submission = get().submission;

    if (!submission) {
      console.log('zustand:deleteBlock:submission not found');
      return;
    }

    const result = await patchXSubmission(
      submission.id,
      {
        operation: XSubmissionAPIOperations.UPDATE,
        blockId: block.id,
        block: {
          name,
          ...(content && { content: content }),
        },
      },
      ''
    );

    if (result?.message === 'success') {
      const freshSubmission = await fetchXSubmission(submission.id, '');
      set({ submission: freshSubmission });
      get().submissionToSectionsById(section_id);
    }
  },
  deleteBlock: async (block) => {
    console.log('zustand:deleteBlock', block.id);
    const { section_id } = block;
    const submission = get().submission;

    if (!submission) {
      console.log('zustand:deleteBlock:submission not found');
      return;
    }

    const result = await patchXSubmission(
      submission.id,
      {
        operation: XSubmissionAPIOperations.DELETE,
        blockId: block.originalBlockId,
      },
      ''
    );

    if (result?.message === 'success') {
      // await fetchXSubmission(submission.id, '').then(() => {
      //   get().submissionToSectionsById(section_id);
      // });
      const newSection = submission.blocks[section_id].filter((b) => b.id !== block.originalBlockId);
      set(
        produce((state) => {
          state.submission.blocks[section_id] = newSection;
        })
      );
      get().submissionToSectionsById(section_id);
    }
  },
  moveBlock: (block, direction) => {
    console.log('zustand:moveBlock');
    if ((block as any).order === 0) {
      console.error("Can't move block up - this is the first block");
      return;
    }
    const blockId = block.originalBlockId;

    const sections = get().sections;
    let blocks = sections[block.section_id];
    if (!blocks) {
      console.error('zustand:moveBlock: section not found', block.section_id);
      return;
    }

    const newBlock = blocks.find((b) => b.id === blockId);

    if (!newBlock?.id) {
      console.error(`Block ${block} does not exists`);
      return;
    }

    blocks = sortBy(blocks, 'order');
    const index = findBlockIdxById(blockId, blocks);

    const prevBlock = blocks[index - 1];
    const prevBlockOrder = prevBlock?.order;
    const nextBlockOrder = blocks[index + 1]?.order;

    const newBlocks = removeBlockFromSection(blockId, blocks);

    if (direction === 'up') {
      if (!prevBlock) {
        console.error("Can't move block up - this is the first block");
        return;
      }
      newBlock.order = findNextFreeOrder(prevBlockOrder - 50, blocks, true, 'dec');

      if (prevBlockOrder === 0) {
        newBlocks[0] = { ...prevBlock, order: (block as any).order };
        newBlock.order = 0;
      }
    } else {
      newBlock.order = findNextFreeOrder(nextBlockOrder + 50, blocks, true, 'inc');
    }

    newBlocks.push(newBlock as any);

    set(
      produce((state) => {
        state.sections[block.section_id] = newBlocks;
      })
    );
    //renumber order after each move
    get().submissionToSectionsById(block.section_id);
    console.log(`✅ Moved block ${blockId} ${direction}`);
  },
  openSettings: (block, mode) => {
    set(
      produce((state) => {
        state.settings = { open: true, mode, block };
      })
    );
  },
  openModal: ({ section_id, ...block }, mode) => {
    switch (mode) {
      case 'create':
        set(
          produce((state) => {
            state.modal = {
              open: true,
              mode: 'create',
              block: { section_id },
            };
          })
        );
        break;
      case 'edit':
        if (block) {
          const { page, originalBlockId, height } = block as BlockFragment;
          const submission = get().submission;
          if (!submission) {
            console.error('zustand:openModal: submission not found');
            return;
          }

          const originalBlock = getOriginalBlock(originalBlockId, submission.blocks[section_id]);

          if (!originalBlock) {
            console.error('zustand:openModal: originalBlock not found');
            return;
          }

          set(
            produce((state) => {
              state.modal = {
                open: true,
                mode,
                block: { ...originalBlock, section_id, page, height, heading: originalBlock.name },
              };
            })
          );
        }
        break;
      default:
        break;
    }
  },
  closeSettings: () =>
    set(
      produce((state) => {
        state.settings = toolClosedState;
      })
    ),
  closeModal: () =>
    set(
      produce((state) => {
        state.modal = toolClosedState;
      })
    ),
  scrollIntoView: (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
}));

export default usePaginatorStore;

const toolClosedState = { open: false, mode: undefined, block: undefined };
