import React, { forwardRef } from 'react';

import clsx from 'clsx';

import type { UIComponentSizes, UIComponentSize } from '../../@types/types';

const sizes: UIComponentSizes = {
  small: 'h-10',
  normal: 'h-10',
};

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  errors?: string | string[];
  iconName?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  placeholderText?: string;
  size?: UIComponentSize;
}

const Input = forwardRef<any, InputProps>(
  (
    { className, errors, id, isDisabled, isReadOnly, isRequired, name, placeholderText, size = 'normal', ...props },
    ref
  ) => (
    <input
      className={clsx(
        'aui-input',
        'shadow-sm text-sm appearance-none border rounded-md border-gray-300 w-full py-2 px-3 text-gray-700 focus:ring-indigo-500 focus:border-indigo-500',
        isDisabled && 'aui-input--disabled',
        isReadOnly && 'aui-input--readonly',
        errors && 'aui-input--errors',
        sizes[size],
        className
      )}
      disabled={isDisabled}
      id={id || name}
      name={name}
      placeholder={placeholderText}
      readOnly={isReadOnly}
      ref={ref}
      required={isRequired}
      {...props}
    />
  )
);

Input.displayName = 'Input';

export default Input;
