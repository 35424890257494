import './index.css';

import React, { useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import qs from 'qs';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import type {
  ClauseLibrary,
  EDDocumentStatus,
  EDDocumentType,
  EDDocumentVisibilityStatus,
  GenericObject,
  S3Schema,
  SubmissionPayload,
  TemplatePayload,
  UIFormValues,
} from '../../../@types/types';
import Confirm from '../../components/Confirm';
import { downloadDocument } from '../../helpers/downloadDocument';
import { getClausesEditorContent } from '../../helpers/getClausesEditorContent';
import { resolveRefs } from '../../helpers/resolveSchema';
import { updateSubmissionLegacy, updateTemplate } from '../../mutations';
import { createPdfDocument } from '../../queries/createPdfDocument';
import { getPdfDocument } from '../../queries/getPdfDocument';
import { useDynamicForm } from '../DynamicForm';
import { WysiwygEditor } from '../WysiwygEditor';
import { VerticalTabs } from './components';
import VerticalTabsContext from './contexts/VerticalTabsContext';

type MutationData = TemplatePayload | SubmissionPayload;

const getDataForMutation = (
  formState: UIFormValues,
  status: EDDocumentStatus,
  activeSchema: S3Schema | null | undefined,
  editorContentState: GenericObject<string>
): MutationData => {
  const data: MutationData = {
    risk_details: formState,
    status,
  };
  if (activeSchema && activeSchema.db_name !== 'clause_library') {
    data.editor_content = {
      id: activeSchema.db_name,
      content: editorContentState[activeSchema.db_name],
    };
  }
  return data;
};

interface WysiwygManagerProps {
  isPdfDocumentCreated: boolean;
  entityClauses: ClauseLibrary;
  editorContent: GenericObject<string>;
  documentType: EDDocumentType | '';
  status: EDDocumentStatus | undefined;
  visibilityStatus: EDDocumentVisibilityStatus | undefined;
  isTemplate?: boolean;
  schemas: S3Schema[] | undefined;
  isFetching: boolean;
  refetch: () => void;
}

export const WysiwygManager: React.FC<WysiwygManagerProps> = ({
  editorContent,
  documentType,
  status,
  visibilityStatus,
  entityClauses,
  isTemplate,
  schemas,
  isFetching,
  refetch,
}) => {
  const [entityClausesState, setEntityClausesState] = useState<ClauseLibrary>(entityClauses);
  const [editorContentState, setEditorContentState] = useState<GenericObject<string>>({
    ...editorContent,
    clause_library: getClausesEditorContent(entityClausesState),
  });
  const [isValid, setIsValid] = useState(false);
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [hasEditorUnsavedChanges, setHasEditorUnsavedChanges] = useState<boolean>(false);
  const [destinationSchema, setDestinationSchema] = useState<S3Schema | null>(null);
  const [unsavedChangesModalVisible, setUnsavedChangesModalVisible] = useState<boolean>(false);
  const toggleUnsavedChangesModalVisible = () => setUnsavedChangesModalVisible(!unsavedChangesModalVisible);

  const downloadPdfMutation = useMutation(() => getPdfDocument(id!, queryParams.authToken as string, !!isTemplate), {
    onSuccess: (data) => {
      if (data?.data?.url) {
        downloadDocument(data.data.url);
      }
    },
  });

  const createPdfAndDownloadMutation = useMutation(
    () => createPdfDocument(id!, queryParams.authToken as string, !!isTemplate),
    {
      onSuccess: () => {
        downloadPdfMutation.mutate();
      },
    }
  );

  const { mutate: mutateSubmissionAndDownloadPdf, isLoading: isSubmissionUpdatingAndDownloadingPdfLoading } =
    useMutation((values: SubmissionPayload) => updateSubmissionLegacy(id!, values, queryParams.authToken as string), {
      onSuccess: async (data) => {
        downloadPdfMutation.mutate();

        await refetch();
        setEditorContentState({ ...data?.data.editor_content });
      },
    });

  const { mutate: mutateTemplateAndDownloadPdf, isLoading: isTemplateUpdatingAndDownloadingPdfLoading } = useMutation(
    (values: TemplatePayload) => updateTemplate(id!, values, queryParams.authToken as string),
    {
      onSuccess: async (data) => {
        createPdfAndDownloadMutation.mutate();

        await refetch();
        setEditorContentState(data?.data.editor_content);
      },
    }
  );

  const { mutate: mutateSubmission, isLoading: isSubmissionLoading } = useMutation(
    (values: SubmissionPayload) => updateSubmissionLegacy(id!, values, queryParams.authToken as string),
    {
      onSuccess: async (data) => {
        await refetch();
        setEditorContentState({ ...data?.data.editor_content });
        if (destinationSchema) {
          setActiveSchema(destinationSchema);
          setDestinationSchema(null);
          toggleUnsavedChangesModalVisible();
        }
      },
    }
  );

  const { mutate: mutateTemplate, isLoading: isTemplateLoading } = useMutation(
    (values: TemplatePayload) => updateTemplate(id!, values, queryParams.authToken as string),
    {
      onSuccess: async (data) => {
        await refetch();
        setEditorContentState(data?.data.editor_content);
        if (destinationSchema) {
          setActiveSchema(destinationSchema);
          setDestinationSchema(null);
          toggleUnsavedChangesModalVisible();
        }
      },
    }
  );

  useEffect(() => {
    setEditorContentState(editorContent);
  }, [editorContent]);

  useEffect(() => {
    setEntityClausesState(entityClauses);
  }, [entityClauses]);

  const isLoading =
    isSubmissionLoading ||
    isTemplateLoading ||
    isSubmissionUpdatingAndDownloadingPdfLoading ||
    isTemplateUpdatingAndDownloadingPdfLoading ||
    isFetching;

  const schemaFormFields =
    schemas &&
    schemas
      .reduce((acc: string[][], schema) => {
        return [...acc, Object.keys(schema.properties)];
      }, [])
      .flat();
  const deduplicatedFormFields = [...new Set(schemaFormFields)]; //--downlevelIteration
  const schema = schemas?.[0];
  resolveRefs(schema);
  const [activeSchema, setActiveSchema] = useState<S3Schema | null | undefined>(schema);

  const { getFormState } = useDynamicForm();

  const handleHeaderClick = (schema: S3Schema) => {
    if (hasEditorUnsavedChanges) {
      setDestinationSchema(schema);
      setUnsavedChangesModalVisible(true);
    } else {
      setActiveSchema(schema);
    }
  };

  const handleEditorUpdate = (content: string) => {
    const newContent = { ...editorContentState, [activeSchema!.db_name]: content };
    setHasEditorUnsavedChanges(!isEqual(editorContent, newContent));
    setEditorContentState(newContent);
  };

  const handleEditorSave = async (newStatus: EDDocumentStatus = '') => {
    const data = getDataForMutation(getFormState(), newStatus, activeSchema, editorContentState);
    if (isTemplate) {
      mutateTemplate(data);
    } else {
      mutateSubmission(data);
    }
    setHasEditorUnsavedChanges(false);
  };

  const handleStatusUpdate = async (newStatus: EDDocumentStatus = '') => {
    if (isTemplate) {
      const data = getDataForMutation(getFormState(), newStatus, activeSchema, editorContentState);
      mutateTemplate(data);
    } else {
      mutateSubmission({
        status: newStatus,
      });
    }
  };

  const handleVisibilityStatusUpdate = async (newVisibilityStatus: EDDocumentVisibilityStatus) => {
    if (isTemplate) {
      mutateTemplate({ visibility_status: newVisibilityStatus });
    }
  };

  const handleStartOver = async () => {
    mutateSubmission({ status: 'PRE-DRAFT' });

    return navigate(`/submissions/${id}${location.search}`);
  };

  const handleSaveAndDownloadPdf = async (newStatus: EDDocumentStatus = '') => {
    const data = getDataForMutation(getFormState(), newStatus, activeSchema, editorContentState);
    if (isTemplate) {
      mutateTemplateAndDownloadPdf(data);
    } else {
      mutateSubmissionAndDownloadPdf(data);
    }
    setHasEditorUnsavedChanges(false);
  };

  const verticalTabsContextValue = {
    mutateSubmission,
    mutateTemplate,
    entityClausesState,
    setEntityClausesState,
    editorContentState,
    setEditorContentState,
    isMutateSubmissionInProgress: isSubmissionLoading,
    isMutateTemplateInProgress: isTemplateLoading,
    isTemplate,
  };

  return (
    <div className="flex-1 relative z-0 flex overflow-hidden">
      <aside className="relative xl:flex xl:flex-col flex-shrink-0 w-1/3 lg:w-1/4 border-l border-gray-200 overflow-y-auto wysiwyg-manager-side-panel bg-white">
        <VerticalTabsContext.Provider value={verticalTabsContextValue}>
          <VerticalTabs
            activeSchemaName={activeSchema!.db_name}
            onSelectSchema={handleHeaderClick}
            schemas={schemas}
            status={status}
            setIsValid={setIsValid}
            isNavigationLocked={isLoading}
            isDisabled={documentType === 'MRC' ? false : status === 'In Progress'}
            documentType={documentType}
            onStartOver={handleStartOver}
            hasEditorUnsavedChanges={hasEditorUnsavedChanges}
          />
        </VerticalTabsContext.Provider>
      </aside>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none wysiwyg-manager-side-panel">
        <div className="inset-0 py-6 pt-0 h-full pb-0">
          {schemas?.map((schema) =>
            schema.db_name === activeSchema?.db_name ? (
              <WysiwygEditor
                fields={deduplicatedFormFields}
                name={schema.db_name}
                key={schema.name}
                editorContent={editorContent}
                onUpdate={handleEditorUpdate}
                onSave={handleEditorSave}
                onStatusUpdate={handleStatusUpdate}
                onVisibilityStatusUpdate={handleVisibilityStatusUpdate}
                onSaveAndDownloadPdf={handleSaveAndDownloadPdf}
                isDownloadingPdf={createPdfAndDownloadMutation.isLoading}
                isLoading={isLoading}
                isValid={isValid}
                status={status}
                visibilityStatus={visibilityStatus}
                documentType={documentType}
                shouldShowBackToEdButton={!isTemplate}
                hasEditorUnsavedChanges={hasEditorUnsavedChanges}
              />
            ) : null
          )}
        </div>
        <Confirm
          open={unsavedChangesModalVisible}
          onClose={toggleUnsavedChangesModalVisible}
          title="You have unsaved changes"
          description="Some of the changes you have made will be lost if you proceed without saving."
          confirmText="Discard Changes"
          onConfirm={() => {
            setHasEditorUnsavedChanges(false);
            setActiveSchema(destinationSchema);
            toggleUnsavedChangesModalVisible();
            setDestinationSchema(null);
          }}
          secondaryConfirmText="Save & Continue"
          onSecondaryConfirm={() => {
            handleEditorSave(status);
          }}
          isDisabled={isSubmissionLoading || isTemplateLoading}
        />
      </main>
    </div>
  );
};
