import { sortBy } from 'lodash';

import { getOriginalBlockId } from '../helpers';
import type { BlockFragment, XSubmissionBlock } from '../types';

export const findBlockIdxById = (id: string, blocks: BlockFragment[]): number => blocks?.findIndex((b) => b.id === id);

export const removeBlockFromSection = (id: string, blocks: BlockFragment[]): BlockFragment[] => {
  return blocks?.filter((b) => {
    return getOriginalBlockId(b.id) !== id;
  });
};

export const findNextFreeOrder = (current: number, blocks: BlockFragment[], sort = true, direction = 'inc'): number => {
  const sorted = sort ? sortBy(blocks, 'order') : blocks;
  const isOccupied = sorted.find((b) => b.order === current);
  const value = direction === 'inc' ? 50 : -50;

  if (!isOccupied) {
    return current;
  } else {
    return findNextFreeOrder(current + value, sorted, false, direction);
  }
};

export const getOriginalBlock = (id: string, blocks: XSubmissionBlock[]): XSubmissionBlock | undefined =>
  blocks?.find((b) => b.id === id);
