import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import InputField from './InputField';

interface InputTextareaProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  className?: string;
  descriptionText?: string;
  id?: string;
  isRequired?: boolean;
  labelText?: string;
  name: string;
  value?: string;
  errors?: string;
}
const InputTextarea: React.FC<InputTextareaProps> = ({
  className,
  descriptionText,
  id,
  isRequired,
  labelText,
  name,
  onChange,
  value,
  errors,
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    name={name}
    errors={errors}
  >
    <textarea
      className={clsx(
        'aui-input aui-input-textarea shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md',
        'shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 mb focus:shadow-outline',
        errors && 'aui-input--errors'
      )}
      id={id || name}
      name={name}
      required={isRequired}
      value={value}
      onChange={onChange}
      rows={3}
    />
  </InputField>
);

InputTextarea.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  labelText: PropTypes.string,
};

InputTextarea.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  labelText: undefined,
};

export default InputTextarea;
