import React, { useState } from 'react';

import clsx from 'clsx';

import { useDynamicForm } from '../../../DynamicForm';
import { Controls, Editor } from '../../../WysiwygEditor/components';
import { useEditor } from '../../../WysiwygEditor/components/Editor/hooks/use-editor';
import { infoTextClasses } from '../EditingModal';
import { classes } from '../PageBlock';

export interface WysiwygEditorProps {
  fields: string[];
  name: string;
  content: string;
  onUpdate: (content: string) => void;
  isValid?: boolean;
  isLoading?: boolean;
  hasEditorUnsavedChanges?: boolean;
  title?: string;
  handleTitleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  columns: 1 | 2;
}

export const WysiwygEditor: React.FC<WysiwygEditorProps> = ({
  fields,
  name,
  content,
  columns,
  title,
  onUpdate,
  handleTitleChange,
}) => {
  const [unformattedPaste, setUnformattedPaste] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const { getFormState } = useDynamicForm();
  const editor = useEditor({
    name,
    getFormState,
    getPreviewState: () => false,
    suggestionFields: fields,
    editorContent: { [name]: content },
    editable: true,
    documentType: '',
    onUpdate,
    unformattedPaste,
  });

  const toggleUnformattedPaste = () => setUnformattedPaste(!unformattedPaste);

  return (
    <div className="page-A4-width">
      <div className="w-full">
        <div className={'relative rounded-lg bg-white border mb-2 border-gray-200 '}>
          {editor && (
            <Controls
              className="z-50"
              editor={editor}
              isDisabled={false}
              unformattedPaste={unformattedPaste}
              onUnformattedPasteClick={toggleUnformattedPaste}
            />
          )}
          <div className={clsx(classes.body[columns])}>
            <div className={clsx('h-full w-full', classes.title[columns])}>
              <textarea
                className={clsx(
                  ' text-gray-400 opacity-75 active:opacity-100 focus:opacity-100 active:text-gray-800 focus:text-gray-800',
                  'h-full w-full border-none prose wysiwyg prose-sm outline-none resize-none font-semibold leading-5'
                )}
                onChange={handleTitleChange}
                value={title}
                placeholder="Block title"
              />
            </div>
            <div className={clsx('overflow-y-auto h-64', classes.content[columns], '!bg-transparent')}>
              <Editor
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
                editor={editor}
                isDisabled={false}
              />
            </div>
          </div>
        </div>
        <p className={clsx(infoTextClasses)}>
          This box has the exact same width as the text in the contract. Whatever you type here will be shown in the
          exact same way.
        </p>
      </div>
    </div>
  );
};
