export const getDocumentName = (documentType: string): string | null => {
  switch (documentType) {
    case 'MRC':
      return 'MRC';
    case 'BERMUDA_MRC':
      return 'Bermuda Headline MRC';
    case 'BERMUDA_MRC_WHITE_LABEL':
      return 'White Labelled Bermuda Headline MRC';
    case 'BERMUDA_DECLARATION':
      return 'Bermuda Carrier Declaration';
    case 'BERMUDA_DECLARATION_RELM_EO_CYBER':
      return 'Bermuda Carrier Declaration Relm EO Cyber E-Contract';
    case 'EOC_OPEN_MARKET':
      return 'EOC - Open Market';
    case 'EOC_OPEN_MARKET_BERMUDA':
      return 'EOC - Open Market (Bermuda)';
    case 'EOC_BINDER':
      return 'EOC - Binder';
    default:
      return null;
  }
};
