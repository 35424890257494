import { keyToTitle } from '../helpers';
import type { PageEngineSections } from '../store/types';
import type { BlockFragment, NavigationSection } from '../types';

export const getNavigationSections = (data: PageEngineSections): NavigationSection[] => {
  /**
   * 1. sections id is the same as raw key
   * 2. first block is always the section header
   * */
  const sections = Object.keys(data);

  return sections?.map((section) => ({
    section: {
      title: keyToTitle(section),
      id: section,
    },
    blocks: getNavigationBlocks(data[section]),
  }));
};

export const getNavigationBlocks = (blocks: BlockFragment[]): BlockFragment[] => {
  return blocks
    ?.map?.(({ heading, ...block }, idx) =>
      idx === 0 || !heading ? undefined : { ...block, heading: heading?.split(':')[0] ?? '' }
    )
    .filter(Boolean) as any;
};

export const extractContent = (html: string): string | null => {
  return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
};
