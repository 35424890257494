import axios from 'axios';

import type {
  XSubmissionCreatePayload,
  XSubmissionDeletePayload,
  XSubmissionPatchResponse,
  XSubmissionUpdatePayload,
} from '../containers/ContractBuilder/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const patchXSubmission = async (
  id: string,
  data: XSubmissionCreatePayload | XSubmissionUpdatePayload | XSubmissionDeletePayload,
  authToken: string
): Promise<XSubmissionPatchResponse> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'patch',
    url: `${process.env.REACT_APP_API_URL}/submissions-spa-${auth.authProvider}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
