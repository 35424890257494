import clsx from 'clsx';

const colourScheme = {
  red: 'text-red-1000 bg-red-100',
  grape: 'text-grape-1000 bg-grape-100',
  violet: 'text-violet-1000 bg-violet-100',
  indigo: 'text-indigo-1000 bg-indigo-100',
  blue: 'text-blue-1000 bg-blue-100',
  cyan: 'text-cyan-1000 bg-cyan-100',
  teal: 'text-teal-1000 bg-teal-100',
  green: 'text-green-1000 bg-green-100',
  orange: 'text-orange-1000 bg-orange-100',
  pink: 'text-pink-1000 bg-pink-100',
};

const newColourScheme = {
  complete: 'text-green-700 bg-green-200',
  incomplete: 'text-blue-700 bg-blue-200',
  not_started: 'text-gray-700 bg-gray-200',
  error: 'text-red-700 bg-red-200',
  warning: 'text-orange-700 bg-orange-200',
};

const kindsMap = {
  normal: 'text-sm h-12 px-3 font-medium rounded',
  small: 'text-xs h-5 px-2 font-medium rounded-full',
};

const Status = ({ status, color = 'blue', statusText, className = '', kind }) => {
  const text = statusText || status;
  const finalColor = newColourScheme[status] || colourScheme[color];

  return (
    <div className={clsx('inline-flex items-center justify-center ', kindsMap[kind], finalColor, className)}>
      {text}
    </div>
  );
};

Status.defaultProps = {
  kind: 'normal',
};

export default Status;
