import React, { useState } from 'react';

import clsx from 'clsx';

import Icon from '../../../components/Icon';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { useSearchQuery } from '../../../hooks/useSearchQuery';
import { classNames } from '../../../utils';
import { keyToTitle } from '../helpers';
import type { BlockFragment } from '../types';
import BlockMenu from './BlockMenu';

export const titleClasses = ['leading-normal'];

export const classes = {
  body: {
    1: [],
    2: ['grid', 'grid-cols-4', 'auto-rows-min'],
    withTopBorder: ['border-t', 'border-gray-300', 'pt-2'],
  },
  sectionHeader: ['col-span-4', 'font-bold', 'capitalize', 'w-full'],
  title: { 2: ['pl-3', 'row-span-6', ...titleClasses], 1: ['pl-3', 'mx-2', ...titleClasses] },
  content: {
    1: ['relative', 'px-6', 'page-block--full'],
    2: ['relative', 'col-start-2', 'col-span-3', 'pl-1 pr-6', 'page-block--grid'],
  },
};

interface PageBlockProps {
  blocks: BlockFragment[];
  isFirst: boolean;
  className?: string;
}

export const PageBlock = ({ blocks, isFirst, className }: PageBlockProps) => {
  const block = blocks?.[0];
  const { heading } = block;

  const isSectionTitle = block.type === 'title';
  const isFragment = block.type === 'fragment';
  // const isFirstFragment = block.type === 'fragment-start';
  const hasTopBorder = (!!heading || (isFragment && isFirst)) && !isFirst && block.columns === 2;
  const hasContextMenu = heading;

  if (block.columns === 0) {
    return null;
  }

  const FragmentViewComponent = {
    1: FullView,
    2: GridView,
  }[block.columns ?? 2];

  return (
    <div
      className={clsx(
        'relative',
        'page-block--wrapper',
        classes.body[block.columns],
        hasTopBorder && classes.body.withTopBorder,
        className
      )}
    >
      <LoadingOverlay active={block.isLoading} />
      {blocks?.map((block, idx) => (
        <React.Fragment key={`${block.id}-${idx}`}>
          {block.heading && (
            <div className={clsx(block.columns !== 0 && classes.title[block.columns])}>
              <div id={isSectionTitle ? block.id : `${block.id}-heading-${block.order}`}>
                {isSectionTitle ? (
                  <SectionTitle title={block.heading ?? ''} />
                ) : (
                  <BlockTitle title={block.heading ?? ''} />
                )}
              </div>
            </div>
          )}
          {!isSectionTitle && <FragmentViewComponent block={block} />}
        </React.Fragment>
      ))}
      {hasContextMenu && <BlockMenu block={block} className="absolute right-0 top-2" />}
    </div>
  );
};

interface ViewProps {
  block: BlockFragment;
}

export const GridView = ({ block }: ViewProps) => {
  const { content } = block;
  return (
    <div id={block.id} className={clsx(classes.content[2])}>
      {content ? <div dangerouslySetInnerHTML={{ __html: content ?? '' }} /> : null}
      <PropsDebug {...block} />
    </div>
  );
};

export const FullView = ({ block }: ViewProps) => {
  const { content } = block;
  return (
    <div id={block.id} className={clsx(classes.content[1])}>
      {content ? <div dangerouslySetInnerHTML={{ __html: content ?? '' }} /> : null}
      <PropsDebug {...block} />
    </div>
  );
};

const PropsDebug = ({ ...props }: Omit<BlockFragment, 'content'>) => {
  const { id, order, height, section_id, type, page } = props;
  const query = useSearchQuery();
  const [open, setOpen] = useState(false);

  const openBox = () => setOpen(true);
  const closeBox = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  return query.get('debug') ? (
    <div
      onClick={openBox}
      className={classNames(
        `top-0  transition-all duration-100 ease-in-out
    absolute p-1 z-10
    text-xs
    -right-48
      flex gap-2`,
        'rounded-lg'
      )}
    >
      {open && (
        <div onClick={closeBox} className="z-20">
          <Icon name="cross" className="h-5 w-5 text-red-500 absolute left-5 top-8" />
        </div>
      )}
      {!open && (
        <>
          <Icon name="info" className="h-3 w-3" />
          <span>
            {height} | {order}
          </span>
        </>
      )}
      {!!open && (
        <pre>
          <div className="h-5">&nbsp;</div>
          {JSON.stringify(
            {
              id,
              order,
              height,
              sectionId: section_id,
              type,
              page,
            },
            null,
            4
          )}
        </pre>
      )}
    </div>
  ) : null;
};

interface TitleProps {
  title: string;
}

const SectionTitle = ({ title }: TitleProps) => (
  <h2 className={clsx(classes.sectionHeader)}>
    <strong>{keyToTitle(title)}</strong>
  </h2>
);

const BlockTitle = ({ title }: TitleProps) => (
  <p>
    <strong>{title}</strong>
  </p>
);
