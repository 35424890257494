import React from 'react';

import clsx from 'clsx';

import type { UIComponentBase, UIComponentSize, UIComponentSizes } from '../../@types/types';

const sizes: UIComponentSizes = {
  small: 'w-4 h-4 border-2',
  normal: 'w-10 h-10 border-4',
};

interface LoaderProps extends Pick<UIComponentBase, 'className'> {
  size?: UIComponentSize;
}

const Loader: React.FC<LoaderProps> = ({ className, size = 'normal' }) => (
  <div
    className={clsx('border-gray-500 rounded-full animate-spin', sizes[size], className)}
    data-testid="loader"
    style={{ borderTopColor: '#868e96' }}
  />
);

export default Loader;
