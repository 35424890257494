import type React from 'react';

import type { S3SchemaProperties } from '../@types/types';
import H2 from './components/H2';
import InputCurrency from './components/InputCurrency';
import InputTemperature from './components/InputTemperature';
import InputTextarea from './components/InputTextarea';
import AddressAutocomplete from './components/schema/AddressAutocomplete';
import Group from './components/schema/Group';
import InputCalendar from './components/schema/InputCalendar';
import InputCheckboxGroup from './components/schema/InputCheckboxGroup';
import InputInteger from './components/schema/InputInteger';
import InputMatrix from './components/schema/InputMatrix';
import InputMultiSelect from './components/schema/InputMultiSelect';
import InputNumber from './components/schema/InputNumber';
import InputPercentage from './components/schema/InputPercentage';
import InputRadioGroup from './components/schema/InputRadioGroup';
import InputSelect from './components/schema/InputSelect';
import InputSelectApiConnected from './components/schema/InputSelectApiConnected';
import InputSelectCurrency from './components/schema/InputSelectCurrency';
import InputSelectSearchable from './components/schema/InputSelectSearchable';
import InputText from './components/schema/InputText';
import Repeater from './components/schema/Repeater';
import Section from './components/schema/Section';
import SectionRepeater from './components/schema/SectionRepeater';

export const componentMap: Record<string, React.FC<any>> = {
  AddressAutocomplete,
  Group,
  H2,
  InputCalendar,
  InputCurrency,
  InputInteger,
  InputMatrix,
  InputNumber,
  InputPercentage,
  InputRadioGroup,
  InputCheckboxGroup,
  InputMultiSelect,
  InputSelect,
  InputSelectApiConnected,
  InputSelectSearchable,
  InputSelectCurrency,
  InputTemperature,
  InputText,
  InputTextarea,
  Repeater,
  Section,
  SectionRepeater,
};

export const getComponentType = (item: S3SchemaProperties): React.FC<any> | false => {
  const component = item['ui:component'];
  if (component) {
    return componentMap[component];
  }

  if (item.type === 'object' && 'amount' in item.properties && 'currency' in item.properties) {
    return componentMap.InputCurrency;
  }

  if (item.type === 'object' && 'value' in item.properties && 'unit' in item.properties) {
    return componentMap.InputTemperature;
  }

  if (item.type === 'array' && 'items' in item) {
    return componentMap.Repeater;
  }

  if ('title' in item && 'properties' in item) {
    return componentMap.H2;
  }

  if (item.type === 'string' && item.format === 'date-time') {
    return componentMap.InputCalendar;
  }

  if ('enum' in item && JSON.stringify(item.enum) === JSON.stringify(['Yes', 'No'])) {
    return componentMap.InputRadioGroup;
  }

  if ('enum' in item) {
    return componentMap.InputSelect;
  }

  if (item.type === 'string') {
    return componentMap.InputText;
  }

  if (item.type === 'number') {
    return componentMap.InputNumber;
  }

  if (item.type === 'object' && !item.title) {
    return componentMap.Group;
  }

  return false;
};
