import type { Node } from 'html2json';
import { html2json, json2html } from 'html2json';
import { flattenDeep, flow, groupBy, keys, map, partialRight, sortBy, values } from 'lodash';
import { v5 as uuidv5 } from 'uuid';

import { FOOTER_H, HEADER_H, MIN_LINE_HEIGHT, TB_CONTENT_PADDING, USE_AMOUNT_OF_PAGE } from './constants';
import { assignPages } from './functions/assign-pages';
import { bootstrapBlock } from './functions/bootstrap-block';
import { checkForTagPresent } from './functions/check-for-tag-present';
import { listSplitter } from './functions/list-splitter';
import { splitOnTag } from './functions/split-on-tag';
import { tableSplitter } from './functions/table-splitter';
import { HASH_SEED, SECTION_TYPES } from './settings';
import type { PageEngineSections } from './store/types';
import type { XSubmissionBlock, XSubmissionData } from './types';
import type { BlockFragment } from './types';

const LS_ORDER_KEY = 'last-order';

export const getHashId = (payload: string): string => uuidv5(payload, HASH_SEED).split('-')[0];
export const keyToTitle = (key: string): string => key.split('_').join(' ');
export const getOriginalBlockId = (id: string) => id.split('#')[0];
export const createFragmentedBlockId = (id: string, idx: number, content: string): string =>
  [id, idx, getHashId(content)].join('#');

const getElementHeight = (element: HTMLElement | null) => {
  if (!element) return 0;
  const style = window.getComputedStyle(element);
  const computed = Math.ceil(parseFloat(style.height.split('px')[0]));
  const height = typeof computed === 'string' ? element.offsetHeight : computed;

  return (
    ['margin-top', 'margin-bottom', 'border-top-width']
      .map((prop: string) => parseInt(style[prop as any]))
      .reduce((total, side) => total ?? 0 + side, height) ?? 0
  );
};

export const getFullOuterHeightById = (id: string) => getElementHeight(document.getElementById(id));

export const getAvailablePageContentHeight = () =>
  //page#template is in src/containers/ContractBuilder/Header.tsx
  (getFullOuterHeightById('page#template') - HEADER_H - FOOTER_H - TB_CONTENT_PADDING) * USE_AMOUNT_OF_PAGE;

export const normaliseBlocks = (blocks: XSubmissionBlock[]): BlockFragment[] => {
  const { section_id } = blocks[0];
  // prettier-ignore
  const rest = flow(
    partialRight(
        map,
        spliceBlockOnTag
      ),
    flattenDeep
  )(blocks) as unknown as BlockFragment[];

  return [
    {
      //section title
      originalBlockId: section_id,
      id: section_id,
      heading: section_id,
      content: '',
      order: 0,
      page: 0,
      height: MIN_LINE_HEIGHT,
      type: 'title',
      columns: SECTION_TYPES[section_id],
      section_id,
    },
    ...rest,
  ];
};

export const splitNodeByType = (node: Node): Node | Node[] => {
  switch (true) {
    case checkForTagPresent(node, ['table']):
      return tableSplitter(node);
    case checkForTagPresent(node, ['ul', 'ol', 'dl']):
      return listSplitter(node);
    default:
      return [node];
  }
};

export const spliceBlockOnTag = (block: XSubmissionBlock): BlockFragment[] => {
  /**
   * 1. splice blocks on tag to fragments
   * 2. split every table fragment into rows
   * 3. split every list fragment into separate list entries
   * 4. return
   *  */
  const { content, id, name, section_id } = block;
  const json = html2json(content ?? '');

  if (!json)
    return [
      bootstrapBlock({
        originalBlockId: id,
        id,
        content,
        name,
        columns: SECTION_TYPES[section_id],
        section_id,
      }),
    ];

  const splitted = splitOnTag(json).map(splitNodeByType);

  return flattenDeep(splitted)
    ?.map(json2html)
    ?.map((html: string, idx: number, array: string[]) =>
      bootstrapBlock({
        originalBlockId: id,
        name,
        section_id,
        content: html,
        id: createFragmentedBlockId(id, idx, html),
        columns: html.startsWith('<hr') ? 0 : SECTION_TYPES[section_id],
        type: array.length === 1 ? 'block' : idx === 0 ? 'fragment-start' : 'fragment',
      })
    );
};

export const resetFakeOrder = () => localStorage.setItem(LS_ORDER_KEY, JSON.stringify(0));

export const getFakeOrder = () => {
  const item = localStorage.getItem(LS_ORDER_KEY);
  let order = JSON.parse(item ?? '') ?? 0;

  order += 1000;
  localStorage.setItem(LS_ORDER_KEY, JSON.stringify(order));
  return order;
};

export const groupBlocksByPages = (blocks: BlockFragment[]): BlockFragment[][] => values(groupBy(blocks, 'page'));
export const groupBlocksByOriginalId = (blocks: BlockFragment[]): BlockFragment[][] =>
  values(groupBy(blocks, 'originalBlockId'));

export const sortBlocks = (blocks: BlockFragment[]): BlockFragment[] => sortBy(blocks, ['order']);

export const processServerSubmission = (blocks: XSubmissionData['blocks']): PageEngineSections =>
  keys(blocks).reduce((acc, section_id) => ({ ...acc, [section_id]: processBlocksFlow(blocks[section_id]) }), {});

export const processBlocksFlow = (blocks: XSubmissionBlock[]) =>
  // prettier-ignore
  flow(
    normaliseBlocks,
    sortBlocks,
    assignPages(
      getAvailablePageContentHeight())
  )(blocks);

export const refreshBlocksFlow = (blocks: BlockFragment[]) => {
  // prettier-ignore
  const height = getAvailablePageContentHeight();
  return assignPages(height)(blocks);
};
export const handleSectionsRefresh = (blocks: PageEngineSections): PageEngineSections =>
  keys(blocks).reduce((acc, section_id) => ({ ...acc, [section_id]: refreshBlocksFlow(blocks[section_id]) }), {});

export function float2int(value: number): number {
  return value | 0;
}

export const debug = (args: any, module = 'page-engine') => {
  if (process.env.NODE_ENV === 'development') {
    console.debug(`[${module}]: `, args);
  }
};

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
