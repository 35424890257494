import { Outlet, Route, Routes, useParams } from 'react-router-dom';

import withPrivateRoute from '../containers/Auth/PrivateRoute';
import ContractForm from '../containers/ContractBuilder/ContractFormWrapper';
import { ContractView } from '../containers/ContractBuilder/ContractView';
import Header from '../containers/ContractBuilder/Header';
import { useSubmissionDataExperimental } from '../hooks';

const Layout = () => {
  return (
    <>
      <div className="shadow">
        <Header />
      </div>
      <Outlet />
    </>
  );
};

const ContractBuilderPage = () => {
  const { data, status } = useSubmissionDataExperimental();
  const { id } = useParams<{ id: string }>();

  if (status === 'loading') {
    return (
      <div className="loader flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (status === 'error') {
    console.error(`Unable to load submission: ${id}`);
    return (
      <div className="loader flex justify-center items-center">Unable to load submission, please try again later</div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="form" element={<ContractForm key="form" data={data} />} />
        <Route path="contract" element={<ContractView key="contract" data={data} />} />
      </Route>
    </Routes>
  );
};

export default withPrivateRoute({})(ContractBuilderPage);
