import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import qs from 'qs';

import type { UISelectionType } from '../../../../../@types/types';
import InputSelect from '../../../../components/InputSelect';
import Modal from '../../../../components/Modal';
import { createTemplate } from '../../../../mutations';

const openTemplateInNewTab = (id: string) => {
  if (id) {
    const win = window.open(`/templates/${id}`, '_blank');
    win?.focus();
  }
};

const AdminCreateTemplateModal = ({
  existingTemplates,
  handleClose,
  setShouldFetchTemplates,
}: {
  existingTemplates: UISelectionType[];
  handleClose: () => void;
  setShouldFetchTemplates: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleCopyFromTemplate = async (copyFromTemplateId: any) => {
    try {
      handleClose();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const { id } = await createTemplate(
        {
          copy_from_template: copyFromTemplateId,
        },
        queryParams.authToken as string
      );
      setShouldFetchTemplates(true);
      openTemplateInNewTab(id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal open className="w-full lg:w-1/2 sm:w-5/6" onClose={handleClose} title="Create template">
      <InputSelect
        onChange={(value) => value && handleCopyFromTemplate(value)}
        labelText="Select from existing templates"
        options={existingTemplates}
      />
    </Modal>
  );
};

export default AdminCreateTemplateModal;
