import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import { useQueryClient } from 'react-query';

import type { GenericObject, UIChildrenMappingProps, UIMapChildrenFn } from '../../../@types/types';
import pickCommaSeparate from '../../helpers/pickCommaSeparate';
import type { InputSelectSchemaProps } from './InputSelect';
import InputSelectSearchable from './InputSelectSearchable';

type Entity = GenericObject & {
  id: string;
  name: string;
  address: GenericObject<string>;
};

interface InputSelectApiConnectedProps extends InputSelectSchemaProps {
  formValues: UIChildrenMappingProps['formValues'];
  ownKey: string;
  mapChildren: UIMapChildrenFn;
  setFormValues: UIChildrenMappingProps['setFormValues'];
}

const InputSelectApiConnected = ({ formValues, ownKey, setFormValues, ...props }: InputSelectApiConnectedProps) => {
  const queryClient = useQueryClient();
  const entityData: Entity[] = get(queryClient.getQueryData(['brokers']), 'data', []);
  const handleSelectChange = (id: string) => {
    const clonedFormValues = cloneDeep(formValues);
    const entity = entityData.find((e: Entity) => e.id === id);
    set(clonedFormValues, ownKey, entity?.id);
    setFormValues(clonedFormValues);
  };

  const itemFormatter = (value: string) => {
    const entity = entityData.find((e) => e.id === value);
    const addressElements = ['addressLine1', 'addressLine2', 'city', 'country', 'postCode'];

    return entity ? (
      <div>
        <div>{entity.name}</div>
        <div className="text-gray-700 text-sm">{pickCommaSeparate(entity.address, addressElements)}</div>
      </div>
    ) : null;
  };

  const formatter = (value: string): string | undefined => {
    const entity = entityData.find((e) => e.id === value);

    return entity?.name;
  };

  return (
    <InputSelectSearchable
      {...props}
      item={{ enum: entityData.map((entity) => ({ name: entity.name, value: entity.id })) }}
      onChange={handleSelectChange}
      formatter={formatter}
      itemFormatter={itemFormatter}
    />
  );
};

export default InputSelectApiConnected;
