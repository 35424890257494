import { useEffect, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { range } from 'lodash';

export interface PaginationProps {
  pageSize: number;
  totalResults: number;
  onPageChange: (page: number) => void;
}

export default function Pagination({ pageSize, totalResults, onPageChange }: PaginationProps) {
  const [page, setPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  useEffect(() => {
    setPage(0);
    setMaxPages(Math.ceil(totalResults / pageSize));
  }, [pageSize, totalResults]);

  const changePage = (page: number) => {
    setPage(page);
    onPageChange(page);
  };

  const getDots = () => (
    <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
      ...
    </span>
  );

  function isInArray(value: number, array: number[]) {
    return array.indexOf(value) > -1;
  }

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{1 + page * pageSize}</span> to{' '}
            <span className="font-medium">
              {(page + 1) * pageSize > totalResults ? totalResults : (page + 1) * pageSize}
            </span>{' '}
            of <span className="font-medium">{totalResults}</span> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a
              onClick={() => page > 0 && changePage(page - 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {range(0, maxPages)
              .map((i) => {
                if (
                  maxPages > 5 &&
                  !isInArray(i, [0, maxPages - 1, page - 1, page, page + 1]) &&
                  !(page < 2 && i === 2)
                ) {
                  return "'dots'";
                }

                return (
                  <a
                    key={i}
                    onClick={() => changePage(i)}
                    aria-current={i === page ? 'page' : undefined}
                    className={
                      i === page
                        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer'
                    }
                  >
                    {i + 1}
                  </a>
                );
              })
              .filter(function (item, pos, arr) {
                return pos === 0 || item !== arr[pos - 1];
              })
              .map((e) => (e === "'dots'" ? getDots() : e))}
            <a
              onClick={() => page + 1 < maxPages && changePage(page + 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
