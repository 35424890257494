import { PageBadge } from './PageBadge';

export const PageFooter = ({ children }: any) => (
  <div
    id="footer"
    className={`h-header w-full  rounded-b
  border-t border-gray-300 bg-gray-200
  flex flex-col p-4
  z-0
  absolute left-0 bottom-0
  `}
  >
    <PageBadge icon="lock" text={'page footer'} />
    <div className="py-4 px-10 h-full">{children}</div>
  </div>
);
