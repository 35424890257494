import axios from 'axios';

import type { Submission } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchSubmissions = async (authToken: string): Promise<Submission[]> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/submissions${auth.authProvider === 'al' ? '-spa-al' : ''}?experimental=1`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
