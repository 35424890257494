import type { Node } from 'html2json';
import { isArray } from 'lodash';

export const splitOnTag = (node: Node): Node[] => {
  if (!node?.child) return [];

  if (isArray(node.child)) {
    return node.child.map((child) => ({ node: 'root', child: [child] }));
  } else {
    return [{ node: 'root', child: [node?.child] }];
  }
};
