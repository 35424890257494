import type { RefObject } from 'react';

export type BlockType = 'title' | 'fragment' | 'break' | 'block' | 'clause' | 'fragment-start';

export type BlockFragment = {
  page: number;
  type: BlockType;
  section_id: string;
  originalBlockId: string;
  columns: BlockDisplayTypeWithPB;
  isLoading?: boolean;
  heading: string;
  content: string;
  height: number;
  order: number;
  id: string;
};

export type Page = BlockFragment[];

export type DivRefType = RefObject<HTMLDivElement>;

export type SplittedFragment = {
  tag: string; //p, div, table, etc...
  content: string;
  length: number;
  height: number;
};

export interface SplitterOptions {
  pageHeight: number;
  availableHeight: number;
  isHigherThanPage: boolean;
}
/**
 *
 * 0 - page break
 * 1 - 1 column
 * 2 - 2 columns
 */
export type BlockDisplayTypeWithPB = 0 | 1 | 2;
export type BlockDisplayType = 0 | 1 | 2;

export type NavigationElement = {
  id: string;
  title: string;
};

export type NavigationSection = {
  section: NavigationElement;
  blocks: BlockFragment[];
};

/* E[X]PERIallMENTAL SUBMISSION API */

export enum XSubmissionAPIOperations {
  CREATE = 'CREATE_SUBMISSION_BLOCK',
  UPDATE = 'UPDATE_SUBMISSION_BLOCK',
  DELETE = 'DELETE_SUBMISSION_BLOCK',
  MOVE_UP = 'MOVE_UP_SUBMISSION_BLOCK', // TODO: move up/down
  MOVE_DOWN = 'MOVE_DOWN_SUBMISSION_BLOCK', // TODO: move up/down
}

export type XSubmissionCreatePayload = {
  operation: XSubmissionAPIOperations.CREATE;
  block: {
    content: string;
    name: string;
    section_id: string;
  };
};

export type XSubmissionUpdatePayload = {
  operation: XSubmissionAPIOperations.UPDATE;
  blockId: string;
  block:
    | {
        content: string;
        name: string;
      }
    | {
        content: string;
      }
    | {
        name: string;
      };
};

export type XSubmissionDeletePayload = {
  operation: XSubmissionAPIOperations.DELETE;
  blockId: string;
};

export type XSubmissionBlock = {
  content: string;
  section_id: string;
  created_at?: string;
  sk?: string;
  pk?: string;
  id: string;
  name: string;
};

export type XSubmissionData = {
  created_at: string;
  sk: string;
  pk: string;
  id: string;
  name: string;
  data_items: unknown;
  class_of_business: string;
  blocks: Record<string, XSubmissionBlock[]>;
};
export type XSubmissionPatchResponse = {
  message: 'success' | 'error';
};
