import { useEffect, useState } from 'react';

import clsx from 'clsx';
import get from 'lodash.get';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Status from '../../components/Status';
import ajv from '../../helpers/ajv';

const NAVIGATOR_CONTAINER_OFFSET = 88;
const HEADER_OFFSET = 80;

const NavItem = ({ onClick, isValid, isActive, title, index }: any) => (
  <button className="my-4 flex items-center cursor-pointer focus:outline-none" onClick={onClick} type="button">
    <div
      className={clsx(
        'rounded-full h-6 w-6 flex justify-center items-center font-semibold text-xs relative',
        isActive ? 'bg-blue-500 text-white' : 'bg-gray-200 border text-gray-700'
      )}
    >
      {isValid ? <Icon name="circle-check" className="h-6 w-6" /> : index + 1}
    </div>

    <p className={clsx('mx-3 transition', isActive ? 'text-black font-medium' : 'text-gray-700 hover:text-gray-900')}>
      {title}
    </p>

    {isValid && <Status kind="small" statusText="Complete" status="complete" />}

    {isActive && <Status kind="small" statusText="Incomplete" status="incomplete" />}

    {!isValid && !isActive && <Status kind="small" statusText="Not started" status="not_started" />}
  </button>
);

interface FormNavigationProps {
  canSubmit: boolean;
  formValues: any;
  handleSubmit: (...args: any) => void;
  schema: any;
}

const FormNavigation = ({ canSubmit, formValues, handleSubmit, schema }: FormNavigationProps) => {
  const properties = get(schema, 'properties', {});
  const sortedKeys = Object.keys(properties).sort((a, b) => properties[a].qid - properties[b].qid);

  const [isSticky, setIsSticky] = useState<boolean | never>();
  const [isConfirmed, setIsConfirmed] = useState<boolean | never>();

  const handleScroll = () => setIsSticky(window.pageYOffset > NAVIGATOR_CONTAINER_OFFSET);

  const handleLinkClick = (linkId: string): void => {
    const header = document.getElementById(linkId);
    if (header) {
      window.scrollTo({ top: header.offsetTop - HEADER_OFFSET, behavior: 'smooth' });
    }
  };

  const validate = ajv.compile(schema);
  const isValid = validate(formValues);

  useEffect(() => {
    if (!isValid && isConfirmed) {
      setIsConfirmed(false);
    }
  }, [isValid]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // const handleSetIsConfirmed = () => setIsConfirmed(!isConfirmed);

  return (
    <div className="relative">
      <div className={clsx('shadow rounded bg-white pl-4 py-4 ml-6 pr-4 min-w-96', isSticky && 'fixed top-5')}>
        <div className="relative mb-3">
          <div className="absolute top-4 bottom-4 left-3 w-px bg-gray-200" />
          {sortedKeys.map((key, index) => {
            const section = properties[key] || {};
            const requiredKeys = section.required || [];
            const sectionFormValues = get(formValues, key) || {};
            const formKeys = Object.keys(sectionFormValues).filter((value) => requiredKeys.includes(value));

            const validate = ajv.compile({
              ...schema,
              properties: { [key]: section },
              required: [key],
            });

            const isValid = validate(formValues);
            const isActive = formKeys.length > 0 && !isValid;

            return (
              <NavItem
                index={index}
                isActive={isActive}
                isValid={isValid}
                key={key}
                onClick={() => handleLinkClick(key)}
                title={properties?.[key]?.title}
              />
            );
          })}
        </div>
        <div className="flex border-t pt-6 pb-4 px-4 mr-4 border-gray-300 justify-between">
          <Button
            as="button"
            size="small"
            className="h-10"
            isDisabled={!canSubmit || !isConfirmed}
            kind="primary"
            onClick={() => handleSubmit()}
          >
            Save progress
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormNavigation;
