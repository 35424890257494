import type { BlockDisplayTypeWithPB } from './types';

/*
 * this is used in submission adapter for old submission api and in PageBlock component
 * gives order to submission adapter
 */
export const SECTION_TYPES: Record<string, BlockDisplayTypeWithPB> = {
  risk_details: 2,
  clause_free_text: 1,
  notices: 1,
  information: 1,
  security_details: 2,
  subscription_agreement: 2,
  fiscal_and_regulatory: 2,
  broker_remuneration_deductions: 2,
};

export const HASH_SEED = '0031e07a-1272-11ed-b07c-c7c3158b87cc';
