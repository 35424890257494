import axios from 'axios';

import type { XSubmissionData } from '../containers/ContractBuilder/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchXSubmission = async (id: string, authToken: string): Promise<XSubmissionData> => {
  const auth = await getRequestAuth(authToken);

  return (
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/submissions-spa-${auth.authProvider}/${id}?experimental=1`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.token,
      },
    })
  )?.data;
};
