import React, { useState } from 'react';

import moment from 'moment';

import type { Action, Column, Filter, TemplateShort, UISelectionType } from '../../../@types/types';
import type { DropdownFilter } from '../../../@types/types';
import { MODALS } from '../../constants';
import withPrivateRoute from '../../containers/Auth/PrivateRoute';
import { useModal } from '../../hooks';
import { fetchTemplates } from '../../queries';
import { Table } from './components';
import { useDataQuery } from './hooks';

const columns: Column[] = [
  {
    label: 'UMR',
    key: 'risk_details.umr',
    headingCellClassName: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    dataCellClassName: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
  },
  {
    label: 'Assigned to',
    key: 'user.email',
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
  },
  {
    label: 'Updated at',
    key: 'updated_at',
    sortable: true,
    formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
  },
  {
    label: 'Visibility',
    key: 'visibility_status',
    sortable: true,
  },
  {
    label: 'Version',
    key: 'version',
  },
  {
    label: 'Show',
    key: 'show',
    type: 'link',
    href: '/templates/{id}',
    target: '_blank',
    rel: 'noreferrer',
  },
];

const initialFilters: Filter[] = [
  {
    label: 'Unique Market Reference',
    key: 'risk_details.umr',
    type: 'dropdown',
    placeholder: 'All UMRs',
    options: [],
    selected: [],
  },
  {
    label: 'Assigned to',
    key: 'user.email',
    type: 'dropdown',
    placeholder: 'All users',
    options: [],
    selected: [],
  },
  {
    label: 'Visibility status',
    key: 'visibility_status',
    type: 'dropdown',
    placeholder: 'All statuses',
    options: ['DRAFT', 'PUBLISHED', 'ARCHIVED'],
    selected: [],
  },
  {
    label: 'Tags',
    key: 'tag',
    type: 'dropdown',
    placeholder: 'All tags',
    options: [],
    selected: [],
  },
];

export const Templates: React.FC = () => {
  const { showModal } = useModal();
  const [copyFromTemplateOptions, setCopyFromTemplateOptions] = useState([]);
  const { data, status, filters, setFilters, sortOrder, toggleSortOrderDirection, setShouldFetchData } = useDataQuery(
    'templates',
    initialFilters,
    fetchTemplates,
    (data) => {
      const umrsFilterOptions = data?.reduce((prev: string[], curr: TemplateShort) => {
        if (prev.indexOf(curr.risk_details?.umr as string) === -1) {
          prev.push(curr.risk_details?.umr as string);
        }
        return prev;
      }, []);
      umrsFilterOptions.sort();

      const emailsFilterOptions = data?.reduce((prev: string[], curr: TemplateShort) => {
        if (prev.indexOf(curr.user?.email as string) === -1) {
          prev.push(curr.user?.email as string);
        }
        return prev;
      }, []);
      emailsFilterOptions.sort();

      const clonedFilters = [...filters];
      (clonedFilters[0] as DropdownFilter).options = umrsFilterOptions;
      (clonedFilters[1] as DropdownFilter).options = emailsFilterOptions;
      setFilters([...clonedFilters]);

      const copyFromTemplateDropdownOptions = data?.reduce((prev: UISelectionType[], curr: TemplateShort) => {
        const currentUmr = curr.risk_details?.umr;
        const currentId = curr.id;
        if (currentUmr && currentId && !prev.find((i) => i.name === currentUmr)) {
          prev.push({ name: currentUmr, value: currentId });
        }
        return prev;
      }, []);
      copyFromTemplateDropdownOptions.sort((a: { name: number }, b: { name: number }) => (a?.name > b?.name ? 1 : -1));

      setCopyFromTemplateOptions(copyFromTemplateDropdownOptions);
    }
  );

  const actions: Action[] = [
    {
      key: 'add_template',
      label: 'New Template',
      onClick: () => {
        showModal(MODALS.ADMIN_CREATE_TEMPLATE, {
          existingTemplates: copyFromTemplateOptions,
          setShouldFetchTemplates: setShouldFetchData,
        });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      data={data}
      status={status}
    />
  );
};

export const AdminTemplates = withPrivateRoute({ adminOnly: true })(Templates);
