import type { Node } from 'html2json';

import { createNode } from './create-node';

const traverse = require('traverse');

export const tableSplitter = (node: Node | undefined): Node[] => {
  /**
   * We know for sure that incoming node looks like this:
   * table > tbody > tr[]
   *
   * We can extract every row "tr" and split it into chunks of N rows.
   * The N is calculated based on the number of columns in the table and amount of text within cells.
   */
  let tableAttr: any = {};
  let tbodyAttr: any = {};

  const rows = traverse(node?.child).reduce((acc: Node[], node: Node | Node[] | string) => {
    if (typeof node === 'object' && !(node instanceof Array)) {
      if (node?.tag === 'tr') {
        acc.push(node);
      }

      if (node.tag === 'table') {
        tableAttr = node.attr;
      }
      if (node.tag === 'tbody') {
        tbodyAttr = node.attr;
      }
    }

    return acc;
  }, []);

  const recreateTable = (row: Node): Node => ({
    node: 'root',
    child: [createNode('table', [createNode('tbody', [row], tbodyAttr)], tableAttr)],
  });

  return rows.map(recreateTable);
};
