import { resolveRefs } from '../../helpers/resolveSchema';
import { useScrollTop } from '../../hooks/useScrollTop';
import ContractForm from './ContractForm';
import schemaData from './submissionSchema.json';
import type { XSubmissionData } from './types';

interface ContractFormWrapperProps {
  data: XSubmissionData | undefined;
  // ...schema: any
}

const ContractFormWrapper = ({ data }: ContractFormWrapperProps) => {
  useScrollTop();
  resolveRefs(schemaData);

  let currentForm = schemaData.properties.SubmissionForm;

  return (
    <ContractForm currentContractData={data} schemaData={{ ...currentForm, definitions: schemaData.definitions }} />
  );
};

export default ContractFormWrapper;
