import { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import Divider from '../../../components/Divider';
import Icon from '../../../components/Icon';
import { getNavigationSections } from '../functions/navigation';
import usePaginatorStore from '../store';
import type { NavigationSection } from '../types';
import BlockMenu from './BlockMenu';

export const Navigation = () => {
  const sectionsRef = useRef(usePaginatorStore.getState().sections);

  useEffect(() => {
    usePaginatorStore.subscribe((state) => (sectionsRef.current = state.sections));
  }, []);

  const sections = getNavigationSections(sectionsRef.current);

  return (
    <div className=" h-auto">
      {sections?.map(({ section, blocks }) => (
        <Section key={section.title} blocks={blocks} section={section} />
      ))}
    </div>
  );
};
// 699d981e-251c-431d-ad10-3271b3fc5c64 new submission from new API
// c6c9e6ef-b077-426c-8a2b-ae4b01b2e8a3 less data
type SectionProps = NavigationSection;

const visibleOnHoverClasses = ['group-hover:opacity-100', 'opacity-0'];

export const Section = ({ section: { title, id }, blocks }: SectionProps) => {
  const [open, setOpen] = useState(false);

  const { scrollIntoView, currentSection } = usePaginatorStore(({ currentSection, scrollIntoView }) => ({
    scrollIntoView,
    currentSection,
  }));

  const visible = currentSection;
  const blocksLength = blocks?.length ?? 0;
  const blocksAreEmpty = !blocksLength;
  const closeList = () => setOpen(false);
  const openList = () => {
    if (blocksLength) setOpen(true);
  };
  return (
    <div className="text-sm max-w-xs md:max-w-lg px-8 mx-auto">
      <div
        className={clsx(
          'flex group font-bold capitalize place-items-center justify-between py-2 px-1',
          'hover:bg-gray-50 rounded'
        )}
      >
        <div className="flex gap-2 place-items-center cursor-pointer" onClick={() => scrollIntoView(id)}>
          <Icon
            name="layout"
            className={clsx('p-1 rounded', visible === id ? 'bg-blue-500' : 'bg-gray-800', 'text-white')}
          />
          {title}
        </div>

        <div className="flex gap-2 place-items-center text-gray-400 group-hover:text-gray-800">
          <span className="font-thin">{blocksLength}</span>
          <Icon
            name={open ? 'chevron-up' : 'chevron-down'}
            className={clsx(blocksAreEmpty ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer')}
            onClick={open ? closeList : openList}
          />
          <Icon name="dots-vertical" className={clsx('cursor-pointer')} />
        </div>
      </div>
      {open && (
        <ol>
          {blocks?.map((block) => (
            <li
              key={block.heading}
              className="pl-4 group text-sm hover:bg-gray-50 hover:font-bold p-1 rounded cursor-pointer w-full"
            >
              <div className="flex flex-nowrap place-items-center justify-between w-full">
                <div
                  className="flex flex-nowrap place-items-center gap-2 truncate
        w-full"
                  onClick={() => scrollIntoView(block.id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className={'p-1 h-6 w-6 rounded bg-gray-200 text-white flex-shrink-0'}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                  </svg>
                  <p className="truncate">{block.heading}</p>
                </div>
                <BlockMenu
                  block={block}
                  className={clsx(visibleOnHoverClasses, 'cursor-pointer text-gray-400 group-hover:text-gray-800')}
                />
              </div>
            </li>
          ))}
        </ol>
      )}
      <Divider />
    </div>
  );
};
