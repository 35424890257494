import React from 'react';

import moment from 'moment';

import type { Column, DropdownFilter, Filter } from '../../../@types/types';
import withPrivateRoute from '../../containers/Auth/PrivateRoute';
import { fetchSubmissions } from '../../queries';
import ALL_CLASSES_OF_BUSINESS from './class-of-bussines';
import { Table } from './components';
import { useDataQuery } from './hooks';

const initialFilters: Filter[] = [
  {
    label: 'Unique Market Reference',
    key: 'umr',
    type: 'dropdown',
    placeholder: 'All UMRs',
    options: [],
    selected: [],
  },
  {
    label: 'Class of business',
    key: 'class_of_business',
    type: 'dropdown',
    placeholder: 'All classes',
    options: ALL_CLASSES_OF_BUSINESS,
    selected: [],
  },
];

export const Submissions: React.FC = () => {
  const columns: Column[] = [
    {
      label: 'UMR',
      key: 'umr',
      headingCellClassName: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
      dataCellClassName: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'Class of business',
      key: 'class_of_business',
    },
    {
      label: 'Created at',
      key: 'created_at',
      sortable: true,
      formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
    },
    {
      label: 'Updated at',
      key: 'updated_at',
      sortable: true,
      formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
    },

    {
      label: 'Show',
      key: 'show',
      type: 'link',
      href: '/contract-builder/{id}/contract',
      target: '_self',
      rel: 'noreferrer',
    },
  ];
  const { sortOrder, data, status, filters, setFilters, toggleSortOrderDirection } = useDataQuery(
    'submissions',
    initialFilters,
    fetchSubmissions,
    (data) => {
      const umrsFilterOptions = data?.reduce((prev: string[], curr: { risk_details: { umr: string } }) => {
        if (prev.indexOf(curr.risk_details?.umr as string) === -1) {
          prev.push(curr.risk_details?.umr as string);
        }
        return prev;
      }, []);
      umrsFilterOptions.sort();

      const clonedFilters = [...filters];
      (clonedFilters[0] as DropdownFilter).options = umrsFilterOptions;
      setFilters([...clonedFilters]);
    }
  );

  return (
    <Table
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      data={data}
      status={status}
    />
  );
};

export const AdminSubmissions = withPrivateRoute({ adminOnly: true })(Submissions);
