import { findLast, isEmpty, sumBy } from 'lodash';

import { getFullOuterHeightById } from '../helpers';
import type { BlockFragment } from '../types';

const getPageHeight = (blocks: BlockFragment[], currentPage: number) =>
  sumBy(blocks, ({ height, page }) => (page === currentPage ? height : 0));

export const assignPages =
  (pageHeight: number) =>
  (blocks: BlockFragment[]): BlockFragment[] =>
    blocks.reduce((acc: BlockFragment[], block: BlockFragment) => {
      if (!block || isEmpty(block)) return acc;
      const prev = findLast(acc);
      const isPageBreak = block?.content?.startsWith('<hr');
      let currentPage = prev?.page ?? 0;
      const contentHeight = getFullOuterHeightById(block.id);
      const headingHeight = getFullOuterHeightById(`${block.id}-heading-${block.order}`);
      const height = block.heading ? Math.max(contentHeight, headingHeight) : contentHeight;
      block.height = height;
      const currentPageHeight = getPageHeight(acc, currentPage);
      const nextHeight = currentPageHeight + height;

      const nextPage = (reason?: string) => {
        console.log('nextPage, reason:', reason);
        currentPage += 1;
      };

      const addToPage = (block: BlockFragment) => {
        block.page = currentPage;
        return [...acc, block];
      };

      if (isPageBreak) {
        nextPage('page break');
      }

      if (nextHeight > pageHeight) {
        nextPage(`height ${nextHeight} > pageHeight ${pageHeight}`);
        return addToPage(block);
      } else {
        return addToPage(block);
      }
    }, []);
