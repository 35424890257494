import React, { useEffect, useRef, useState } from 'react';

import { DocumentSearchIcon, PencilIcon, RefreshIcon, SaveIcon } from '@heroicons/react/outline';
import { LogoutIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

import type { EDDocumentStatus, EDDocumentVisibilityStatus, TemplateStatus } from '../../../@types/types';
import Button from '../../components/Button';
import Confirm, { confirmTypes } from '../../components/Confirm';
import { getDocumentName } from '../../helpers/documentName';
import { useDynamicForm } from '../DynamicForm';
import { Controls, Editor, Preview, Switch } from './components';
import { useEditor } from './components/Editor/hooks/use-editor';
import './index.css';
import PublishingOptions from './components/PublishingOptions';

const ICON_CLASSNAME = 'block h-4 w-4 mr-2';

export interface WysiwygEditorProps {
  fields: string[];
  name: string;
  editorContent: { [name: string]: string };
  status: EDDocumentStatus | undefined;
  visibilityStatus: EDDocumentVisibilityStatus | undefined;
  documentType: string;
  isValid: boolean;
  isLoading: boolean;
  isDownloadingPdf: boolean;
  hasEditorUnsavedChanges: boolean;
  shouldShowBackToEdButton: boolean;
  onUpdate: (content: string) => void;
  onSave: (status: EDDocumentStatus | undefined) => void;
  onStatusUpdate: (status: EDDocumentStatus | undefined) => void;
  onVisibilityStatusUpdate: (visibilityStatus: EDDocumentVisibilityStatus) => void;
  onSaveAndDownloadPdf: (status: EDDocumentStatus | undefined) => void;
}

export const WysiwygEditor: React.FC<WysiwygEditorProps> = ({
  fields,
  name,
  editorContent,
  onSave,
  onStatusUpdate,
  onVisibilityStatusUpdate,
  onUpdate,
  onSaveAndDownloadPdf,
  isDownloadingPdf,
  isLoading,
  isValid,
  status,
  visibilityStatus,
  documentType,
  shouldShowBackToEdButton,
  hasEditorUnsavedChanges,
}) => {
  const isDraft = status === 'DRAFT';
  const isDisabled = documentType === 'TEMPLATE_MRC' ? false : documentType !== 'MRC' ? isDraft : false;

  const [restoreOriginalModalVisible, setRestoreOriginalModalVisible] = useState(false);
  const toggleRestoreOriginalModalVisible = () => setRestoreOriginalModalVisible(!restoreOriginalModalVisible);
  const [returnToTradedModalVisible, setReturnToTradedModalVisible] = useState(false);
  const toggleReturnToTradedModalVisible = () => setReturnToTradedModalVisible(!returnToTradedModalVisible);
  const isMrcClauseLibrarySchema = name === 'clause_library';
  const [isPreviewMode, defaultSetPreviewMode] = useState(isDraft || isMrcClauseLibrarySchema);
  // used for retaining scroll position when viewed in preview mode
  const [scrollPosition, setScrollPosition] = useState(0);
  // used for unformatted paste button
  const [unformattedPaste, setUnformattedPaste] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const previewModeRef = useRef(false);

  const search = useLocation().search;
  const authToken = new URLSearchParams(search).get('authToken');
  let decoded: { redirectUrl?: string } | null = null;

  if (authToken) {
    decoded = jwt_decode(authToken);
  }

  const backToTradeEd = () => {
    if (decoded?.redirectUrl) {
      location.href = decoded.redirectUrl;
    }
  };

  const getPreviewState = () => previewModeRef.current;

  const { getFormState, hasUnsavedChanges } = useDynamicForm();
  const editor = useEditor({
    documentType,
    name,
    getFormState,
    getPreviewState,
    suggestionFields: fields,
    editorContent,
    editable: documentType === 'MRC' ? true : status === ('In Progress' as TemplateStatus),
    onUpdate,
    unformattedPaste,
  });
  useEffect(() => {
    if (editorContent[name] && !(editor as any)?.isDestroyed) {
      (editor as any)?.commands?.setContent(editorContent[name]);
    }
  }, [editorContent]);

  const setPreviewMode = (val: boolean) => {
    defaultSetPreviewMode(val);
    previewModeRef.current = val;
  };

  const handlePreviewToggle = () => (isMrcClauseLibrarySchema ? null : setPreviewMode(!isPreviewMode));

  const handleStatusUpdate = (status: EDDocumentStatus | undefined) => {
    if (status === 'DRAFT') {
      onStatusUpdate('In Progress');
    } else {
      if (documentType === 'MRC') {
        onSave('DRAFT');
      } else {
        toggleRestoreOriginalModalVisible();
      }
    }
  };

  useEffect(() => {
    if (isDraft || isMrcClauseLibrarySchema) {
      setPreviewMode(true);
    } else {
      setPreviewMode(false);
    }
  }, [status]);

  useEffect(() => {
    (ref.current as any).scrollTop = scrollPosition;

    const timer = setInterval(() => {
      if (ref.current) {
        setScrollPosition(ref.current.scrollTop);
      }
    }, 300);
    return () => clearInterval(timer);
  }, [isPreviewMode]);

  return (
    <div className="flex flex-row w-full h-full bg-gray-50 lg:justify-center overflow-auto">
      <div className="p-4 w-full">
        <div className="mb-6 flex items-center justify-between">
          <h5 className="text-xl leading-relaxed font-semibold">{getDocumentName(documentType)}</h5>
          <div className="flex justify-end items-end">
            <Button
              isDisabled={isLoading || !(hasUnsavedChanges || (!isDraft && hasEditorUnsavedChanges))}
              onClick={() => onSave(status)}
              className="ml-4 disabled:opacity-50 disabled:cursor-not-allowed p-4 h-10"
              kind="ghost"
              size="small"
            >
              <SaveIcon className={ICON_CLASSNAME} />
              Save Progress
            </Button>
            <Button
              isDisabled={isLoading || !isValid || (isDraft && hasUnsavedChanges)}
              onClick={() => handleStatusUpdate(status)}
              className="ml-4 disabled:opacity-50 disabled:cursor-not-allowed h-10"
              kind="ghost"
              size="small"
            >
              {isDraft ? <PencilIcon className={ICON_CLASSNAME} /> : <RefreshIcon className={ICON_CLASSNAME} />}
              {isDraft ? 'Edit Document' : 'Restore Original'}
            </Button>
            <Button
              isDisabled={isLoading || !isValid || isDownloadingPdf || hasUnsavedChanges}
              onClick={() => {
                onSaveAndDownloadPdf(status);
              }}
              className="ml-4 mr-4 disabled:opacity-50 disabled:cursor-not-allowed h-10"
              kind="ghost"
              size="small"
            >
              <DocumentSearchIcon className={ICON_CLASSNAME} />
              {hasEditorUnsavedChanges ? 'Save and Preview PDF' : 'Preview PDF'}
            </Button>
            {shouldShowBackToEdButton && decoded && (
              <Button
                onClick={
                  hasUnsavedChanges || hasEditorUnsavedChanges ? toggleReturnToTradedModalVisible : backToTradeEd
                }
                className="ml-4 mr-4 disabled:opacity-50 disabled:cursor-not-allowed h-10 rounded-lg"
                kind="primary"
                size="small"
              >
                <LogoutIcon className={clsx(ICON_CLASSNAME, 'text-white')} />
                Return to TradEd
              </Button>
            )}
            {!isDraft && (
              <Switch
                className={documentType === 'TEMPLATE_MRC' ? 'mr-4' : ''}
                isChecked={isPreviewMode}
                onClick={handlePreviewToggle}
                isDisabled={isMrcClauseLibrarySchema}
              />
            )}
            {documentType === 'TEMPLATE_MRC' && (
              <PublishingOptions
                visibilityStatus={visibilityStatus}
                isDisabled={isLoading}
                onVisibilityStatusUpdate={onVisibilityStatusUpdate}
              />
            )}
          </div>
        </div>
        <div
          ref={ref}
          className={
            isPreviewMode
              ? 'editor editor-preview bg-white shadow pt-2'
              : 'editor relative rounded-lg bg-white border-1 shadow mb-2 border-gray-200'
          }
        >
          {editor && !isPreviewMode && (
            <Controls
              editor={editor}
              isDisabled={isDisabled}
              unformattedPaste={unformattedPaste}
              onUnformattedPasteClick={() => setUnformattedPaste(!unformattedPaste)}
            />
          )}
          {isPreviewMode ? (
            editor && <Preview editor={editor} documentType={documentType} />
          ) : (
            <Editor editor={editor} isDisabled={isDisabled} />
          )}
        </div>
      </div>
      <Confirm
        open={restoreOriginalModalVisible}
        onClose={toggleRestoreOriginalModalVisible}
        title="Restore original version"
        description="Are you sure you want to restore the original document? Any edits made to the layout or content will be lost by restoring the original version."
        confirmText="Restore Original"
        onConfirm={() => {
          toggleRestoreOriginalModalVisible();
          onSave('DRAFT');
        }}
      />
      <Confirm
        open={returnToTradedModalVisible}
        onClose={toggleReturnToTradedModalVisible}
        title="Return to TradEd"
        description="You have unsaved changes. Would you like to save the current version of the document before returning to TradEd?"
        confirmText="Save & Continue"
        type={confirmTypes.info}
        onConfirm={() => {
          toggleReturnToTradedModalVisible();
          onSave(status);
          backToTradeEd();
        }}
      />
    </div>
  );
};
