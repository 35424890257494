import Icon from '../../../components/Icon';
import type { SvgIconName } from '../../../components/Svg';

interface PageBadgeProps {
  icon: SvgIconName;
  text: string;
}

export const PageBadge = ({ icon, text }: PageBadgeProps) => {
  return (
    <span className="text-xs flex align-center gap-1 uppercase">
      <Icon name={icon} className="h-3 w-3" />
      {text}
    </span>
  );
};
