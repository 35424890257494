import './App.css';

import React, { useState } from 'react';

import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import ServerErrorModal from './components/ServerErrorModal';
import AdminLayout from './containers/Admin/AdminLayout';
import ForgotPassword from './containers/Auth/ForgotPassword';
import Login from './containers/Auth/Login';
import Logout from './containers/Auth/Logout';
import PasswordForm from './containers/Auth/PasswordForm';
import { PrivateLayout } from './layout';
import { AdminDashboard, AdminSubmissions, AdminTemplates, AdminClauses, NotFoundPage } from './pages';
import ContractBuilderPage from './pages/ContractBuilderPage';

const queryClient = new QueryClient();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

Auth.configure({
  region: 'eu-west-1',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
});

/** Hardcoded submission ID to redirect users to if they land on the `/` route */
/* const SUBMISSION_ID = 'a3518895-ca57-4927-9f74-a4c56784a8d6'; */
/* if (process.env.REACT_APP_MSW_ENABLED === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
} */

const App: React.FC = () => {
  const [serverErrorShown, setServerErrorShown] = useState(false);
  const [serverErrorDetails, setServerErrorDetails] = useState('');

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      let errorMessage = error.message;
      if (error.response?.data) {
        errorMessage = error.response.data.message || JSON.parse(`${error.response.data.replace(/'/g, '"')}`).message;
      }
      setServerErrorDetails(errorMessage);
      if (serverErrorShown) return;
      setServerErrorShown(true);
      return Promise.reject(error);
    }
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <PrivateLayout>
          <ServerErrorModal open={serverErrorShown} setOpen={setServerErrorShown} errorDetails={serverErrorDetails} />
          <Routes>
            {/* PUBLIC */}
            <Route path="/register" element={<PasswordForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<PasswordForm />} />
            {/* ADMIN */}
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route path="submissions" element={<AdminSubmissions />} />
              <Route path="templates" element={<AdminTemplates />} />
              <Route path="clauses" element={<AdminClauses />} />
            </Route>
            {/* CONTRACT BUILDER */}
            <Route path="contract-builder/:id/*" element={<ContractBuilderPage />} />
            {/* 404 */}
            <Route path="/" element={<NotFoundPage />} />
          </Routes>
        </PrivateLayout>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
