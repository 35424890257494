import { useEffect, useRef } from 'react';

import Divider from '../../components/Divider';
import AddBlockButton from './components/AddBlockButton';
import { EditingModal } from './components/EditingModal';
import { Navigation } from './components/Navigation';
import { PageComponent } from './components/Page';
import './contract.css';
import { debug, groupBlocksByPages } from './helpers';
import usePaginatorStore from './store';
import type { PageEngineState } from './store/types';
import type { XSubmissionData } from './types';
import type { BlockFragment } from './types';

interface ContractViewProps {
  data: XSubmissionData | undefined;
  // ...schema: any
}

export const ContractView = ({ data: submission }: ContractViewProps) => {
  const { initSubmission } = usePaginatorStore(({ initSubmission }: PageEngineState) => ({
    initSubmission,
  }));
  const sectionsRef = useRef(usePaginatorStore.getState().sections);

  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => {
    usePaginatorStore.subscribe((state) => (sectionsRef.current = state.sections));
  }, []);

  useEffect(() => {
    debug('fetching fresh data from submission');
    if (submission) initSubmission(submission);
  }, []);

  const sectionIds = Object.keys(sectionsRef.current);

  return (
    <div className="flex bg-gray-50 justify-between overflow-auto min-h-screen">
      <div className="h-auto bg-white w-1/4 flex flex-col">
        <div className="p-3 flex space-between w-full">
          <div className="w-1/2 text-center">Structure</div>
          <div className="w-1/2 text-center">Data points</div>
        </div>
        <Divider />
        <AddBlockButton />
        <Divider />
        <Navigation />
      </div>
      <div className="flex my-4 flex-col gap-y-5 w-full overflow-auto items-center pb-48 pt-5">
        <EditingModal />
        {sectionIds.map((id, secIdx) =>
          groupBlocksByPages(sectionsRef.current[id]).map(
            (page: BlockFragment[], idx: number, pages: BlockFragment[][]) => {
              return (
                <PageComponent
                  sectionId={id}
                  page={page}
                  key={`page#${idx}#${secIdx}`}
                  totalPages={pages.length}
                  pageNumber={idx + 1}
                  isLast={idx === pages.length - 1}
                  isFirst={idx === 0}
                />
              );
            }
          )
        )}
      </div>
    </div>
  );
};
