import React from 'react';

import clsx from 'clsx';

import InputField from './InputField';
import InputReadOnly from './InputReadOnly';

export interface InputTextProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  descriptionText?: string;
  errors?: string | string[];
  formatter?: (value: unknown) => string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  labelText?: React.ReactNode;
  link?: React.ReactNode;
  name?: string;
}

const InputText: React.FC<InputTextProps> = ({
  className,
  descriptionText,
  errors,
  id,
  isDisabled = false,
  isReadOnly,
  isRequired,
  labelText,
  link,
  max,
  min,
  name,
  onChange,
  onInput,
  pattern,
  placeholder,
  step,
  type,
  value,
  ...props
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    errors={errors}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    link={link}
    name={name}
  >
    {isReadOnly && (
      <InputReadOnly
        {...props}
        aria-describedby={descriptionText ? `${name}Description` : undefined}
        name={name}
        value={value}
      />
    )}

    {!isReadOnly && (
      <input
        className={clsx(
          'aui-input h-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md',
          'shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 mb focus:shadow-outline',
          errors && 'aui-input--errors'
        )}
        disabled={isDisabled}
        id={id || name}
        max={max}
        min={min}
        name={name}
        onChange={onChange}
        onInput={onInput}
        pattern={pattern}
        placeholder={placeholder}
        required={isRequired}
        step={step}
        type={type}
        value={value || ''}
      />
    )}
  </InputField>
);

export default InputText;
