type IsNotEmpty = {
  errors: boolean;
  type: string;
  validate: (_schema: unknown, data: string | unknown) => boolean;
};

export const isNotEmpty: IsNotEmpty = {
  errors: false,
  type: 'string',
  validate: (_schema, data) => typeof data === 'string' && data.trim() !== '',
};
