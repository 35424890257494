import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import Dropdown from '../../../components/Dropdown';
import Modal from '../../../components/Modal';
import { getHashId, keyToTitle } from '../helpers';
import usePaginatorStore from '../store';
import type { BlockFragment } from '../types';
import { WysiwygEditor } from './Wysiwyg';

type ModalState = {
  section_id: string;
  content: string;
  name: string;
  type: BlockFragment['type'];
};

export const infoTextClasses = ['text-sm', 'leading-4', 'font-light', 'text-gray-400', 'pt-2'];

const defaultState: ModalState = {
  content: '',
  name: '',
  section_id: 'risk_details',
  type: 'block',
};

export const EditingModal = () => {
  const { modal, sections, closeModal, updateBlock, createBlock } = usePaginatorStore(
    ({ sections, modal, closeModal, updateBlock, createBlock }) => ({
      closeModal,
      updateBlock,
      createBlock,
      sections,
      modal,
    })
  );

  const [state, setState] = useState<ModalState>(defaultState);

  const sectionsOptions = Object.keys(sections).map((id) => ({ label: keyToTitle(id), value: id }));

  const handleSectionChange = ({ value }: any) => setState((s) => ({ ...s, section_id: value }));
  const handleContentChange = (content: string): void => setState((s) => ({ ...s, content }));
  const handleTitleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) =>
    setState((s) => ({
      ...s,
      name: e.target.value,
    }));

  const onModalClose = () => {
    closeModal();
    clearModalState();
  };

  const isCreate = modal.mode === 'create';

  const confirmUpdate = () => {
    const { content = '', name = '', section_id } = state;
    if (!isEmpty(content) && modal.block) {
      isCreate
        ? createBlock({
            ...modal.block,
            id: getHashId(content ?? name ?? new Date().toISOString()),
            content,
            name,
            section_id,
          })
        : updateBlock({
            ...modal.block,
            content,
            name,
            section_id,
            // type,
          });
    }
    onModalClose();
  };

  const clearModalState = () => {
    if (!isEmpty(state.content) || !isEmpty(state.name)) {
      setState(defaultState);
    }
  };

  useEffect(() => {
    if (modal.block) {
      if (isCreate) {
        clearModalState();
        setState((s) => ({ ...s, content: '<p className="opacity-50">Block content</p>' }));
      } else {
        setState({
          section_id: modal.block.section_id,
          content: modal.block.content,
          name: modal.block.heading,
          type: modal.block.type,
        });
      }
    }
  }, [modal, isCreate]);

  if (modal?.block?.columns === 0) {
    return null;
  }

  return (
    <Modal className={clsx(`w-full max-w-6xl`)} open={modal.open} onClose={onModalClose} title={''}>
      <div className="flex flex-col place-items-center gap-3">
        <h1 className="text-lg">{`${isCreate ? 'Create' : 'Edit'}${
          state.name ? ` "${state.name.replaceAll(':', '')}"` : ''
        } block`}</h1>
        <p className="text-gray-400 font-light text-center px-24">
          Please edit the content of this block. Add data points from the right side bar wherever you need by leaving
          your cursor in between the words you want to add the data point and clicking on the “+” icon. You can also
          type “/” and the data point you want right after.
        </p>
        {/* {modal.block?.columns} */}

        <div className="flex justify-between w-full gap-6">
          <WysiwygEditor
            content={state.content ?? ''}
            fields={[]}
            name="value"
            columns={modal?.block?.columns ?? 2}
            hasEditorUnsavedChanges={true}
            isLoading={false}
            onUpdate={handleContentChange}
            title={state.name ?? ''}
            handleTitleChange={handleTitleChange}
          />
          <div className="flex flex-col gap-3">
            <div className="capitalize">
              <Dropdown
                fullwidth
                size={'small'}
                labelText={keyToTitle(state.section_id)}
                className="h-full min-w-fit whitespace-nowrap capitalize border-none"
                items={sectionsOptions}
                onSelect={handleSectionChange}
              />
            </div>
            <div className="border rounded-lg flex flex-col h-full">
              <p className="text-xs leading-none font-normal p-2">Data points used in this block</p>
              <Divider />
              <ol className="p-2">
                <li>Option 1</li>
                <li>Option 2</li>
              </ol>
            </div>
            <p className={clsx(infoTextClasses)}>
              You can mark data points as required in this block using the toggle.
            </p>
          </div>
        </div>

        <div className="flex w-full gap-4 justify-between">
          <Button onClick={onModalClose} className="w-full">
            {'Cancel'}
          </Button>
          <Button onClick={confirmUpdate} className="w-full" kind="primary">
            {isCreate ? 'Create' : 'Save changes'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
