import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import unset from 'lodash.unset';
import moment from 'moment';

import type { S3Schema } from '../../@types/types';
import type { AppError, UIFormValues } from '../../@types/types';

type FormValues = {
  office?: string;
  unapproved_security?: string;
  client_disclaimer_signed?: string;
  unapproved_security_name_section?: string;
  proex_and_cyber?: string;
  contract_type?: string;
  contract_type_section_name?: string;
  declared_to_lineslip?: string;
  profit_commission?: string;
  premium_payment?: {
    settlement_due_date?: string;
    due_to_ed_date?: string;
  };
} & UIFormValues;

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): FormValues; (): any };
type SetFormErrorsFn = (errors: AppError[] | null) => void;

export const useEocOpenMarketBermudaEffects = (
  schema: S3Schema,
  formValues: FormValues,
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn,
  setFormErrors: SetFormErrorsFn
): void => {
  useEffect(() => {
    if (formValues.office !== 'Ed Broking Bermuda') {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'direct_placement', 'No');
      setFormValues(nextFormValues);
    }
    if (
      schema.properties.eoc_or_bid &&
      formValues.office &&
      !['Ed Broking London', 'Cooper Gay London'].includes(formValues.office)
    ) {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'eoc_or_bid', 'EoC');
      unset(nextFormValues, 'proex_and_cyber');
      unset(nextFormValues, 'sections');
      unset(nextFormValues, 'contract_type');
      unset(nextFormValues, 'contract_type_section_name');
      setFormValues(nextFormValues);
    }
  }, [formValues.office]);

  useEffect(() => {
    if (!schema || !schema.properties) {
      return;
    }
    if (!formValues || !formValues.premium_payment) {
      return;
    }
    if (!formValues.premium_payment.settlement_due_date) {
      return;
    }
    if (formValues.premium_payment.due_to_ed_date) {
      return;
    }
    const cloned = cloneDeep(getFormState());
    const nextFormValues: FormValues = {
      ...cloned,
      premium_payment: {
        ...cloned.premium_payment,
        due_to_ed_date: moment(cloned.premium_payment?.settlement_due_date).subtract(10, 'days').toISOString(),
      },
    };
    setFormValues(nextFormValues);
  }, [schema.properties.premium_payment, formValues.premium_payment]);

  useEffect(() => {
    if (formValues.unapproved_security === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'client_disclaimer_signed');
      unset(nextFormValues, 'unapproved_security_name_section');
      setFormValues(nextFormValues);
    }
  }, [formValues.unapproved_security]);

  useEffect(() => {
    if (formValues.client_disclaimer_signed === 'No') {
      setFormErrors([
        {
          dataPath: 'client_disclaimer_signed',
          path: 'client_disclaimer_signed',
          message: 'WARNING! Get the Client Disclaimer Letter signed and escalate this before you proceed further!',
        },
      ]);
    } else {
      setFormErrors(null);
    }
  }, [formValues.client_disclaimer_signed]);

  useEffect(() => {
    if (formValues.proex_and_cyber === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'sections');
      unset(nextFormValues, 'contract_type');
      unset(nextFormValues, 'contract_type_section_name');
      setFormValues(nextFormValues);
    }
  }, [formValues.proex_and_cyber]);

  useEffect(() => {
    if (formValues.declared_to_lineslip === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'profit_commission');
      setFormValues(nextFormValues);
    }
  }, [formValues.declared_to_lineslip]);
};
