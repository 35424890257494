import Icon from '../../../components/Icon';

interface SectionIndicatorProps {
  sectionId?: string;
}

export const SectionIndicator = ({ sectionId }: SectionIndicatorProps) => {
  return sectionId ? (
    <div className="text-xs capitalize absolute top-36 pt-2 left-3 flex gap-1 align-center">
      <Icon name="layout" />
      {sectionId?.split('_').join(' ')}
    </div>
  ) : null;
};
