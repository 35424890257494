import React, { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import type { Location } from 'react-router-dom';
import { Link, Outlet, useLocation } from 'react-router-dom';

import type { UIComponentBase } from '../../../@types/types';
import { getInitials } from '../../helpers/getInitials';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { ModalContextProvider, ModalSwitch } from '../../hooks/useModal';

const navigation = [
  { name: 'Dashboard', href: '/admin', icon: HomeIcon },
  { name: 'Submissions', href: '/admin/submissions', icon: ChartBarIcon },
  { name: 'Templates', href: '/admin/templates', icon: FolderIcon },
  { name: 'Clauses', href: '/admin/clauses', icon: CalendarIcon },
  { name: 'Tags', href: '/admin/tags', icon: InboxIcon },
];

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const getCurrentName = (location: Location) => {
  switch (location.pathname.toLowerCase()) {
    case '/admin/submissions':
      return 'Submissions';
    case '/admin/templates':
      return 'Templates';
    case '/admin/clauses':
      return 'Clauses';
    case '/admin/tags':
      return 'Tags';
    default:
      return 'Dashboard';
  }
};

const AdminLayout: React.FC<UIComponentBase> = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useCurrentUser();
  const currentName = getCurrentName(useLocation());

  return (
    <ModalContextProvider>
      <div className="admin">
        <ModalSwitch />
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img className="h-12 w-auto" src="/Artificial_Logo_White.png" alt="Artificial Logo" />
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            currentName === item.name
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              currentName === item.name ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  {user && (
                    <div className="flex-shrink-0 flex bg-gray-700 p-4">
                      <div className="flex items-center">
                        <div>
                          <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                            <span className="font-medium leading-none text-white">{getInitials(user)}</span>
                          </span>
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-white">{`${user?.attributes?.given_name} ${user?.attributes?.family_name}`}</p>
                          <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                            <Link to="/logout">Logout</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img className="h-12 w-auto" src="/Artificial_Logo_White.png" alt="Artificial Logo" />
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      currentName === item.name
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        currentName === item.name ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
            {user && (
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <div className="flex items-center">
                  <div>
                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                      <span className="font-medium leading-none text-white">{getInitials(user)}</span>
                    </span>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{`${user?.attributes?.given_name} ${user?.attributes?.family_name}`}</p>
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      <Link to="/logout">Logout</Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="py-6">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </ModalContextProvider>
  );
};

export default AdminLayout;
