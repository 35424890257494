const ALL_CLASSES_OF_BUSINESS = [
  'Accident & Health',
  'Aerospace',
  'Alternative Risks',
  'Bermuda - Central Programs',
  'Bermuda - General',
  'Bermuda - Liability Professional Lines',
  'Bermuda - Property',
  'Bermuda - Reinsurance',
  'Bloodstock',
  'Cargo',
  'Casualty & Liability',
  'Cyber',
  'Dubai',
  'Energy - Construction',
  'Energy - Downstream',
  'Energy - General',
  'Energy - Upstream',
  'F&PR',
  'FAJS - Cash in Transit',
  'FAJS - Fine Art',
  'FAJS - General',
  'FAJS - Jewellers Block',
  'General',
  'Marine',
  'Miami',
  'ProEx',
  'Property',
  'Reinsurance',
  'Risk Solutions',
  'Singapore - A&H',
  'Singapore - Facultative',
  'Singapore - General',
  'Singapore - Marine',
  'Singapore - Trade Credit',
  'Singapore - Treaty',
  'Special Contingency',
];

export default ALL_CLASSES_OF_BUSINESS;
