import React from 'react';

import clsx from 'clsx';
import Downshift from 'downshift';
import type { ControllerStateAndHelpers } from 'downshift';

import type { UIComponentSizes, UIComponentSize } from '../../@types/types';
import { getBooleanOrFalse, getValueFromObjOrSimple } from '../helpers/getValue';
import Icon from './Icon';

type MarginSizes = UIComponentSizes;

type DropdownStyle = 'menuWrapper' | 'menuItem' | 'menu';
type DropdownStyles = Record<DropdownStyle, string> & { button: Record<'default' | 'open', string> };

const sizes: UIComponentSizes = {
  normal: 'h-10',
  small: 'h-10',
};

const margins: MarginSizes = {
  normal: 'mt-12',
  small: 'mt-10',
};

const styles: DropdownStyles = {
  button: {
    default:
      'rounded px-3 text-left text-sm font-medium flex items-center justify-between truncate bg-white border border-gray-500 hover:bg-gray-200 focus:outline-none',
    open: 'text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-1000 border border-transparent',
  },
  menuWrapper: 'absolute right-0 top-0 z-10 bg-transparent',
  menu: 'mt-2 border-gray-300 border  rounded max-h-56 min-w-64 overflow-y-auto focus:outline-none shadow-lg ',
  menuItem: 'text-sm font-medium flex items-center px-3 py-2  overflow-hidden bg-white',
};

type Item = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

interface DropdownProps {
  fullwidth?: boolean;
  className?: string;
  initialSelectedItem?: string;
  items?: Array<Item | string>;
  labelText?: string;
  name?: string;
  size?: UIComponentSize;
  onSelect?: (selectedItem: any, stateAndHelpers: ControllerStateAndHelpers<any>) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  fullwidth,
  className,
  items,
  size = 'small',
  labelText,
  onSelect,
  name,
}) => (
  <Downshift itemToString={(item) => (item ? item.value || item : '')} onSelect={onSelect}>
    {({ getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen }) => (
      <div className={clsx('relative text-gray-800', className)}>
        <button
          {...getToggleButtonProps()}
          className={clsx(fullwidth && 'w-full', styles.button.default, sizes[size], isOpen && styles.button.open)}
          name={name}
          type="button"
        >
          {labelText}
          {!isOpen && <Icon name="chevron-down" className="ml-2 fill-current text-gray-700" />}
          {isOpen && <Icon name="chevron-up" className="ml-2 fill-current text-white" />}
        </button>

        {isOpen && (
          <div className={clsx(fullwidth && 'w-full', styles.menuWrapper, margins[size])}>
            <ul {...getMenuProps()} className={styles.menu}>
              {items?.map((item: Item | string, index: number) => (
                <li
                  name={getValueFromObjOrSimple(item, 'value')}
                  key={getValueFromObjOrSimple(item, 'value')}
                  disabled={getBooleanOrFalse(item, 'disabled') ? 'true' : undefined}
                  className={clsx(
                    styles.menuItem,
                    getBooleanOrFalse(item, 'disabled')
                      ? 'text-gray-700 cursor-not-allowed'
                      : 'text-gray-900 cursor-pointer',
                    index === highlightedIndex && 'bg-gray-100',
                    sizes[size]
                  )}
                  {...getItemProps({ disabled: getBooleanOrFalse(item, 'disabled'), index, item })}
                >
                  {getValueFromObjOrSimple(item, 'label')}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )}
  </Downshift>
);

export default Dropdown;
