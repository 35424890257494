import { useEffect, useState } from 'react';

import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
// import { useNavigate } from 'react-router-dom';

import Form from '../../components/Form';
// import ajv from '../../helpers/ajv';
import resolveSchema from '../../helpers/resolveSchema';
import { useMagicLink } from '../../hooks/useMagicLink';
import mapChildren from '../../mapChildren';
import FormNavigation from './FormNavigation';

// const byFileType = (node: { type: string }): boolean => node.type === 'file';

const ContractForm = ({ currentContractData, schemaData }: any) => {
  // const navigate =  useNavigate();

  const prevSubmission = currentContractData.submission;
  const {
    // initialFormValues,
    formValues,
    setFormValues,
  } = useMagicLink(prevSubmission);

  const [
    formErrors,
    // setFormErrors
  ] = useState();

  const clonedSchema = cloneDeep(schemaData);
  // const validate = ajv.compile(schemaData);
  // const isValid = validate(formValues);

  // const forcePush = (path: string) => push(path, { isForced: true });

  const handleChange = (...args: any) => {
    const nextFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(nextFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(nextFormValues, name, value);
    }

    setFormValues(nextFormValues);
  };

  const handleSubmit = async (_formData: any, _bespokeSubmission: any) => {
    const submit = () => console.log('submit');

    try {
      await submit();

      return;
    } catch (error) {
      console.error(error);
    }
  };

  resolveSchema(clonedSchema, formValues);

  useEffect(() => {
    const errors = document.querySelectorAll('[data-error=true]');

    if (errors.length > 0) {
      window.scrollTo({ top: (errors?.[0] as any)?.offsetTop ?? 0 - 80, behavior: 'smooth' });
    }
  }, [formErrors]);

  return (
    <div className="h-full min-h-screen ">
      <div className="mx-10 pb-24 flex items-start mt-12">
        <Form className="max-w-3xl w-full h-screen overflow-y-auto pb-52" id="submissionForm">
          {mapChildren({
            formValues,
            onChange: handleChange,
            parentKey: '',
            parentSchema: clonedSchema,
            setFormValues,
            validationErrors: formErrors,
          } as any)}
        </Form>

        <FormNavigation
          canSubmit={true /* !isSubmitting */}
          formValues={formValues}
          handleSubmit={handleSubmit}
          schema={clonedSchema}
        />
      </div>
    </div>
  );
};

export default ContractForm;
