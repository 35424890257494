import React, { useEffect, useState } from 'react';

import moment from 'moment';

import type { Action, Column, Filter, ClauseShort, CheckboxFilter } from '../../../@types/types';
import { MODALS } from '../../constants';
import withPrivateRoute from '../../containers/Auth/PrivateRoute';
import { useModal } from '../../hooks';
import { fetchClauseLibrary } from '../../queries';
import { Table } from './components';
import { useDataQuery } from './hooks';

const initialFilters: Filter[] = [
  {
    label: 'Types',
    key: 'type',
    type: 'dropdown',
    placeholder: 'All types',
    options: ['Condition', 'Endorsement', 'Exclusion', 'Warranty', 'Wording'],
    selected: [],
  },
  {
    label: 'Tags',
    key: 'tag',
    type: 'dropdown',
    placeholder: 'All tags',
    options: [],
    selected: [],
  },
  {
    label: 'Show archived',
    key: 'deleted_at',
    type: 'checkbox',
    isChecked: false,
    onChange: () => {},
  },
];

const initialColumns: Column[] = [
  {
    label: 'Description',
    key: 'description',
    sortable: true,
    normalizer: (fieldValue = '') => fieldValue.toLowerCase(),
    headingCellClassName: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    dataCellClassName: 'truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
  },
  {
    label: 'Type',
    key: 'type',
    sortable: true,
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? moment(value).format('DD/MM/YYYY HH:mm') : value),
  },
  {
    label: 'Edit',
    key: 'edit',
    type: 'link',
    href: '#',
  },
];

export const Clauses: React.FC = () => {
  const { showModal } = useModal();
  const [columns, setColumns] = useState(initialColumns);
  const [showArchivedClauses, setShowArchivedClauses] = useState<boolean>(false);
  const { sortOrder, data, status, filters, setFilters, toggleSortOrderDirection, setShouldFetchData } = useDataQuery(
    'clauses',
    initialFilters,
    fetchClauseLibrary
  );

  const handleEditClauseClick = (clause: ClauseShort) => () =>
    showModal(MODALS.ADMIN_CLAUSE, { clause, setShouldFetchData, isAdd: false });

  useEffect(() => {
    const clonedFilters = [...filters];
    const showArchivedFilter = clonedFilters[2] as CheckboxFilter;
    showArchivedFilter.onChange = () => setShowArchivedClauses(!showArchivedClauses);
    showArchivedFilter.isChecked = showArchivedClauses;
    setFilters(clonedFilters);

    const clonedColumns = [...columns];
    clonedColumns[3].onClick = handleEditClauseClick;
    setColumns(clonedColumns);
  }, []);

  useEffect(() => {
    const clonedFilters = [...filters];
    const showArchivedFilter = clonedFilters[2] as CheckboxFilter;
    showArchivedFilter.onChange = () => setShowArchivedClauses(!showArchivedClauses);
    showArchivedFilter.isChecked = showArchivedClauses;
    setFilters(clonedFilters);
  }, [showArchivedClauses]);

  const actions: Action[] = [
    {
      key: 'add_clause',
      label: 'New Clause',
      onClick: () => {
        showModal(MODALS.ADMIN_CLAUSE, { setShouldFetchData, isAdd: true });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      data={data}
      status={status}
    />
  );
};

export const AdminClauses = withPrivateRoute({ adminOnly: true })(Clauses);
