import React from 'react';

import clsx from 'clsx';

interface PillBadgeProps {
  children: React.ReactNode;
  className?: string;
}

export const PillBadge = ({ children, className }: PillBadgeProps) => (
  <span
    className={clsx(
      'text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-blue-200 text-blue-700 rounded-full',
      className
    )}
  >
    {children}
  </span>
);
