import ContextMenu from '../../../components/ContextMenu';
import usePaginatorStore from '../store';
import type { PageEngineState } from '../store/types';
import type { BlockFragment } from '../types';

interface BlockMenuProps {
  block: BlockFragment;
  className?: string;
}

const BlockMenu = ({ block, className }: BlockMenuProps) => {
  const { deleteBlock, moveBlock, openModal } = usePaginatorStore(
    ({ deleteBlock, moveBlock, openModal }: PageEngineState) => ({
      deleteBlock,
      moveBlock,
      openModal,
    })
  );

  const onEdit = () => openModal(block, 'edit');
  const onDelete = () => deleteBlock(block);
  const onCreate = () => openModal(block, 'create');
  const onMoveUp = () => moveBlock(block, 'up');
  const onMoveDown = () => moveBlock(block, 'down');
  const onSettings = () => console.log('settings');
  const onComment = () => console.log('comment');

  return (
    <div className={className}>
      <ContextMenu
        options={[
          { title: 'Move up', onClick: onMoveUp, icon: 'arrow-up' },
          { title: 'Move down', onClick: onMoveDown, icon: 'arrow-down' },
          { title: 'Add new block', onClick: onCreate, icon: 'plus' },
          { title: 'Edit block', onClick: onEdit, icon: 'edit' },
          { title: 'Delete block', onClick: onDelete, icon: 'trashcan' },
          { title: 'Comment', onClick: onComment, icon: 'comment' },
          { title: 'Settings', onClick: onSettings, icon: 'settings' },
        ]}
      />
    </div>
  );
};

export default BlockMenu;
