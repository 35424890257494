import type React from 'react';
import { useState, useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';

import type { AppError, EDDocumentType, S3Schema } from '../@types/types';
import { useDynamicForm } from './containers/DynamicForm';
import ajv from './helpers/ajv';
import resolveSchema from './helpers/resolveSchema';
import {
  useBermudaDeclarationEffects,
  useBermudaDeclarationRelmEoCyberEffects,
  useBermudaMrcEffects,
  useEocBinderEffects,
  useEocOpenMarketEffects,
  useEocOpenMarketBermudaEffects,
} from './hooks';
import mapChildren from './mapChildren';

interface DatapointsProps {
  schema: S3Schema;
  documentType: EDDocumentType | '';
  isDisabled: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

const Datapoints: React.FC<DatapointsProps> = ({ schema, isDisabled, setIsValid, documentType }) => {
  const { formValues, onChange, setFormValues, getFormState } = useDynamicForm();
  const [formErrors, setFormErrors] = useState<AppError[] | null>([]);
  const clonedSchema = cloneDeep(schema);
  const [resolvedSchema, setResolvedSchema] = useState(clonedSchema);

  const isEocBinder = documentType === 'EOC_BINDER';
  const isEocOpenMarket = documentType === 'EOC_OPEN_MARKET';
  const isEocOpenMarketBermuda = documentType === 'EOC_OPEN_MARKET_BERMUDA';
  const isBermudaMrc = documentType === 'BERMUDA_MRC';
  const isBermudaMrcWhiteLabel = documentType === 'BERMUDA_MRC_WHITE_LABEL';
  const isBermudaDeclaration = documentType === 'BERMUDA_DECLARATION';
  const isBermudaDeclarationRelmEoCyber = documentType === 'BERMUDA_DECLARATION_RELM_EO_CYBER';

  useEffect(() => {
    const validate = ajv.compile(schema);
    (async () => {
      const isValid = await validate(formValues);
      setIsValid(isValid || true);
    })();
    setResolvedSchema(resolveSchema(clonedSchema, formValues));
    if (formValues && formValues.unapproved_security === 'Yes' && formValues.client_disclaimer_signed === 'No') {
      setIsValid(false);
    }
  }, [formValues]);

  if (isEocOpenMarket) {
    useEocOpenMarketEffects(schema, formValues, setFormValues, getFormState, setFormErrors);
  }
  if (isEocOpenMarketBermuda) {
    useEocOpenMarketBermudaEffects(schema, formValues, setFormValues, getFormState, setFormErrors);
  }
  if (isEocBinder) {
    useEocBinderEffects(schema, formValues, setFormValues, getFormState);
  }
  if (isBermudaMrc || isBermudaMrcWhiteLabel) {
    useBermudaMrcEffects(schema, formValues, setFormValues, getFormState);
  }
  if (isBermudaDeclaration) {
    useBermudaDeclarationEffects(schema, formValues, setFormValues, getFormState);
  }
  if (isBermudaDeclarationRelmEoCyber) {
    useBermudaDeclarationRelmEoCyberEffects(schema, formValues, setFormValues, getFormState);
  }

  const handleChange = async (...args: any) => {
    onChange(...args);
  };

  return mapChildren({
    formValues,
    onChange: handleChange,
    parentKey: '',
    isReadOnly: isDisabled,
    parentSchema: resolvedSchema,
    setFormValues,
    validationErrors: formErrors,
    showQuestionKey: true,
  });
};

export default Datapoints;
