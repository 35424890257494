import React from 'react';

import { useParams } from 'react-router-dom';

import type { UIFormValues } from '../../@types/types';
import { WysiwygManager } from '../containers';
import withPrivateRoute from '../containers/Auth/PrivateRoute';
import { DynamicFormProvider } from '../containers/DynamicForm';
import { getClausesEditorContent } from '../helpers/getClausesEditorContent';
import { resolveRefs } from '../helpers/resolveSchema';
import { useTemplateData } from '../hooks/useTemplateData';

const SingleTemplate: React.FC = () => {
  const { data, documentType, status, isFetching, refetch } = useTemplateData();
  const { id } = useParams<{ id: string }>();

  if (status === 'loading') {
    return (
      <div className=" loader flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  if (status === 'error') {
    console.error(`Unable to load template: ${id}`);
    return (
      <div className="loader flex justify-center items-center">Unable to load template, please try again later</div>
    );
  }

  const riskDetailsData = data?.data?.risk_details;
  const schemas = data?.schemas;
  const schema = schemas?.[0];
  resolveRefs(schema);

  const entityClauses = data?.data?.clauses ?? [];
  let editorContent = data?.data?.editor_content ?? {};
  if (documentType === 'TEMPLATE_MRC') {
    editorContent = { ...editorContent, clause_library: getClausesEditorContent(entityClauses) };
  }

  return (
    <DynamicFormProvider initialValues={riskDetailsData as UIFormValues}>
      <WysiwygManager
        editorContent={editorContent}
        documentType={documentType}
        status={data?.data?.status}
        visibilityStatus={data?.data?.visibility_status ?? 'DRAFT'}
        isPdfDocumentCreated={data?.data?.document_name ? true : false}
        entityClauses={entityClauses}
        isTemplate
        schemas={schemas}
        isFetching={isFetching}
        refetch={refetch}
      />
    </DynamicFormProvider>
  );
};

export const SingleTemplatePage = withPrivateRoute({})(SingleTemplate);
