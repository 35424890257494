import type { Node } from 'html2json';

const traverse = require('traverse');

export const checkForTagPresent = (node: Node | undefined, search: string[]): boolean => {
  const tags = traverse(node?.child).map((node: Node | Node[] | string) => {
    if (typeof node === 'object' && !(node instanceof Array)) {
      return node?.tag ? search.includes(node?.tag) : false;
    }
  });
  return tags.includes(true);
};
