import React, { useContext } from 'react';

import type { ClauseShort } from '../../../../@types/types';
import Button from '../../../components/Button';
import InputSelect from '../../../components/InputSelect';
import VerticalTabsContext from '../../WysiwygManager/contexts/VerticalTabsContext';
import ClauseLibraryContext from '../ClauseLibraryContext';
import { useClause } from '../useClause';

export const SelectOrCreate = () => {
  const { clauseLibrary, setIsAddClause, handleCloseModal } = useContext(ClauseLibraryContext);
  const { entityClausesState, isTemplate } = useContext(VerticalTabsContext);
  const { persistClauses } = useClause();

  const handleAddClauseFromLibraryToSubmission = async (selectedClauseId: string) => {
    const { description, type, content } = clauseLibrary.find((i: ClauseShort) => i.id === selectedClauseId)!;
    try {
      const newClause = {
        description,
        type,
        content,
        id: selectedClauseId,
        is_edited: false,
        index: entityClausesState.length,
        created_at: new Date().toISOString(),
      };
      const newClauses = entityClausesState ? [...entityClausesState, newClause] : [newClause];
      persistClauses(newClauses, newClause);
      handleCloseModal();
    } catch (e) {
      console.log(e);
    }
  };
  const handleCreateNewClauseClick = () => setIsAddClause(true);
  return (
    <>
      <InputSelect
        onChange={(value) => value && handleAddClauseFromLibraryToSubmission(value)}
        labelText="Select from clause library"
        options={clauseLibrary.reduce((filteredClauses: { name: string; value: string }[], currentClause) => {
          const { description, id } = currentClause;
          if (entityClausesState?.find((i: ClauseShort) => i.description === description)) {
            return filteredClauses;
          }
          filteredClauses.push({ name: description, value: id });
          return filteredClauses;
        }, [])}
      />
      <div className="flex flex-col-reverse sm:flex-row mt-5 sm:mt-4 justify-end gap-2 sm:gap-4">
        <Button iconName={'hand-empty'}>
          <a href="mailto:documentproduction@edbroking.com?subject=Clause%20Request:%20[Name]%20-%20(Please%20Attach%20Clause%20Wording)">
            Request a Clause
          </a>
        </Button>
        {!isTemplate && (
          <Button onClick={handleCreateNewClauseClick} kind="primary">
            Create a new Clause
          </Button>
        )}
      </div>
    </>
  );
};
