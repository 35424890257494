import { MIN_LINE_HEIGHT } from '../constants';
import { getFakeOrder } from '../helpers';
import type { BlockDisplayTypeWithPB, BlockFragment, BlockType } from '../types';

type RecreateBlockArgs = {
  id: string;
  originalBlockId: string;
  content: string;
  name: string;
  columns: BlockDisplayTypeWithPB;
  type?: BlockType;
  section_id: string;
};

export const bootstrapBlock = ({
  originalBlockId,
  content,
  id,
  columns = 2,
  type = 'fragment',
  name,
  section_id,
}: RecreateBlockArgs): BlockFragment => ({
  originalBlockId,
  page: 0,
  type,
  content,
  heading: type === 'fragment' ? '' : name,
  height: MIN_LINE_HEIGHT,
  id,
  order: getFakeOrder(),
  columns,
  section_id,
});
