import { useEffect, useMemo } from 'react';

import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import { useSearchQuery } from '../../../hooks/useSearchQuery';
import { groupBlocksByOriginalId } from '../helpers';
import usePaginatorStore from '../store';
import type { PageEngineState } from '../store/types';
import type { BlockFragment, Page } from '../types';
import { PageBlock } from './PageBlock';
import { PageFooter } from './PageFooter';
import { PageHeader } from './PageHeader';

export const pageClasses = `page-A4 relative w-full h-full bg-white shadow-lg rounded z-0
flex flex-col prose prose-sm lg:prose-lg mrc wysiwyg contract-builder`;

interface PageProps {
  page: Page;
  sectionId: string;
  pageNumber: number;
  totalPages: number;
  isLast?: boolean;
  isFirst?: boolean;
}

export const PageComponent = ({ page, totalPages, pageNumber, sectionId }: PageProps) => {
  const { setVisibleSection, visibleSections, refreshSectionById } = usePaginatorStore(
    ({ visibleSections, setVisibleSection, refreshSectionById }: PageEngineState) => ({
      visibleSections,
      refreshSectionById,
      setVisibleSection,
    })
  );

  const { ref, inView } = useInView({});
  const query = useSearchQuery();
  const isDebug = !!query.get('debug');

  const isVisible = useMemo(() => visibleSections.includes(sectionId), [visibleSections, sectionId]);

  useEffect(() => {
    if (inView) {
      setVisibleSection(sectionId);
    }
  }, [inView]);

  const currentHeight = page?.reduce((acc, block) => acc + block.height, 0) ?? 0;

  useEffect(() => {
    // const maxPageContentHeight = getAvailablePageContentHeight();
    if (isVisible) {
      refreshSectionById(sectionId);
    }
  }, [currentHeight]);

  const groupBlocks = useMemo(() => groupBlocksByOriginalId(page), [page]);

  return (
    <div ref={ref} className={pageClasses}>
      <PageHeader sectionId={sectionId} />
      {isDebug && <span className="absolute top-0 right-0 p-2">{currentHeight}</span>}
      <div className={clsx(`w-full z-10 py-8`)} id={`page#${pageNumber}-content`}>
        {groupBlocks.map((blocks: BlockFragment[], idx: number) => (
          <PageBlock blocks={blocks} key={[blocks[0].originalBlockId, idx, pageNumber].join('-')} isFirst={idx === 0} />
        ))}
      </div>
      <PageFooter>
        <p className="flex justify-end">
          Page {pageNumber} of {totalPages}
        </p>
      </PageFooter>
    </div>
  );
};
