import { Menu, Transition } from '@headlessui/react';

import Icon from './Icon';
import type { SvgIconName } from './Svg';

type ContextOption = {
  onClick: () => void;
  title: string;
  icon: SvgIconName;
};

interface ContextMenuProps {
  options: ContextOption[];
}

const ContextMenu = ({ options }: ContextMenuProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <Menu.Button
        className={`inline-flex justify-center rounded-md  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <Icon name={'dots-vertical'} />
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute left-8 -top-10 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {options.map(({ icon, onClick, title }) => (
            <Menu.Item key={title}>
              {({ active }) => (
                <button
                  onClick={onClick}
                  className={`${
                    active ? 'bg-blue-200' : ''
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2 text-grey-400`}
                >
                  <Icon name={icon} /> {title}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ContextMenu;
