import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import H2 from '../../components/H2';
import { pageClasses } from './components/Page';

const Header = () => {
  return (
    <div className="flex justify-between items-center py-6 px-8 bg-white">
      <div className="flex flex-row flex-nowrap gap-3 place-items-center">
        <a
          href="/admin/submissions"
          title="Back to submissions"
          className="
        border rounded-full p-3 border-gray-400
        hover:border-blue-500
        hover:bg-blue-100
        hover:text-blue-500
        "
        >
          <ArrowLeftIcon className="h-4 w-4" />
        </a>
        <H2>Submission Form</H2>
      </div>
      <nav>
        <Link to="form">Edit Variables</Link> <Link to="contract">Edit Contract</Link>
      </nav>
      <div className={pageClasses} id={'page#template'} style={{ visibility: 'hidden', position: 'absolute' }}>
        &nbsp;
      </div>
    </div>
  );
};

export default Header;
